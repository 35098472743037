import service from '@/api/meeting';
import {loading, handleError} from '@/state/generators/helpers';
import {alert} from "../generators/helpers";

export const state = {
    loading: false,
    pager: {},
    additional: {},
    list: [],
    own: [],
    mine: [],
    mineLoading: false,
    today: [],
    todayLoading: false,
    service: service()
}

export const getters = {
    loading: function (state) {
        return !!state.loading
    },
    pager: function (state) {
        return JSON.parse(JSON.stringify(state.pager))
    },
    list: function (state) {
        return state.list
    },
    own: function (state) {
        return state.own
    },
    mine: function (state) {
        return JSON.parse(JSON.stringify(state.mine))
    },
    mineLoading: function (state) {
        return !!state.mineLoading
    },
    today: function (state) {
        return JSON.parse(JSON.stringify(state.today))
    },
    todayLoading: function (state) {
        return !!state.todayLoading
    },
}

export const mutations = {
    'SET_LOADING': function (state, boolean = true) {
        state.loading = boolean
    },

    'SET_LIST': function (state, list) {
        state.list = list
    },

    'SET_OWN': function (state, list) {
        state.own = list
    },

    'SET_PAGER': function (state, pager = {}) {
        state.pager = pager
    },

    'SET_ADDITIONAL': function (state, data = {}) {
        state.additional = data
    },

    'SET_MINE_LOADING': function (state, boolean = true) {
        state.mineLoading = boolean
    },

    'SET_MINE': function (state, list) {
        state.mine = list
    },

    'SET_TODAY_LOADING': function (state, boolean = true) {
        state.todayLoading = boolean
    },

    'SET_TODAY': function (state, list) {
        state.today = list
    },
}

export const actions = {
    own: function ({commit, state}, filters) {
        loading(commit)

        return state.service.fetchOwn(filters).then(response => {
            commit('SET_LIST', response.data)
            commit('SET_OWN', response.data)
            commit('SET_PAGER', response.meta)
            commit('SET_ADDITIONAL', response.additional)
            loading(commit, false)
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    mine: function ({commit, state}, {id, query}) {
        commit('app/SET_LOADING', true, {root: true})
        commit('SET_MINE_LOADING', true)

        return state.service.fetchMine(id, query).then(response => {
            commit('SET_MINE', response.data)
            commit('app/SET_LOADING', false, {root: true})
            commit('SET_MINE_LOADING', false)
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    today: function ({commit, state}, {id, query}) {
        commit('app/SET_LOADING', true, {root: true})
        commit('SET_TODAY_LOADING', true)

        return state.service.fetchMine(id, query).then(response => {
            commit('SET_TODAY', response.data)
            commit('app/SET_LOADING', false, {root: true})
            commit('SET_TODAY_LOADING', false)
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    byProject: function ({commit}, id) {
        return state.service.fetchProjectMeetings(id).then(response => {
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    cancel: function ({commit, state}, id) {
        loading(commit)

        return state.service.cancel(id).then(response => {
            const model = Object.assign({}, response.data)

            commit('REPLACE_ITEM', model);
            commit('SET_ITEM', model);
            alert(commit, this.$t('request.save_is_successful'))
            commit('SET_ERRORS', {});
            loading(commit, false)

            return model;
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    realize: function ({commit, state}, {id, data}) {
        loading(commit)

        return state.service.realize(id, data).then(response => {
            const model = Object.assign({}, response.data)

            commit('REPLACE_ITEM', model);
            commit('SET_ITEM', model);
            alert(commit, this.$t('request.save_is_successful'))
            commit('SET_ERRORS', {});
            loading(commit, false)

            return model;
        }).catch((e) => {
            handleError(e, commit)
        })
    },
}
