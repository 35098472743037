import service from '@/api/people';
import {handleError} from '@/state/generators/helpers';

export const state = {
    service: service('people'),
    loadingSearch: false,
    searchList: [],
    searchFilters: {},
    searchPager: {}
}

export const getters = {
    searchList: (state) => {
        return JSON.parse(JSON.stringify(state.searchList))
    },

    loadingSearch: (state) => {
        return !!state.loadingSearch
    },

    searchEnd: (state) => {
        return state.searchPager?.total === state.searchList?.length
    },
}

export const mutations = {
    'SET_SEARCH_LIST': function (state, list = []) {
        state.searchList = list
    },

    'ADD_TO_SEARCH_LIST': function (state, list = []) {
        state.searchList = [...state.searchList, ...list]
    },

    'SET_LOADING_SEARCH': function (state, loading) {
        state.loadingSearch = loading
    },

    'SET_SEARCH_FILTERS': function (state, filters) {
        state.searchFilters = filters;
    },

    'SET_SEARCH_PAGER': function (state, pager) {
        state.searchPager = pager;
    }
}

export const actions = {
    search: function ({commit, state}, filters) {
        commit('app/SET_LOADING', true, {root: true})
        commit('SET_LOADING_SEARCH', true)

        const data = Object.assign({page: 1}, filters)

        commit('SET_SEARCH_FILTERS', data)

        return state.service.search(data).then(response => {
            commit('SET_SEARCH_LIST', response.data)
            commit('SET_SEARCH_PAGER', response.meta)
            commit('app/SET_LOADING', false, {root: true})
            commit('SET_LOADING_SEARCH', false)
            return response.data
        }).catch((e) => {
            handleError(e, commit)
            commit('SET_SEARCH_FILTERS', {})
        })
    },

    moreSearch: function ({commit, state}) {
        commit('app/SET_LOADING', true, {root: true})
        commit('SET_LOADING_SEARCH', true)

        const data = Object.assign({}, state.searchFilters, {page: (state.searchPager?.current_page || 1) + 1})

        return state.service.search(data).then(response => {
            commit('ADD_TO_SEARCH_LIST', response.data)
            commit('SET_SEARCH_PAGER', response.meta)
            commit('app/SET_LOADING', false, {root: true})
            commit('SET_LOADING_SEARCH', false)
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    clearSearch: function ({commit}) {
        commit('SET_SEARCH_FILTERS', {})
        commit('SET_SEARCH_LIST', [])
        commit('SET_SEARCH_PAGER', {})
    }
}
