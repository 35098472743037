import {handleError, loading} from "../generators/helpers";
import service from '@/api/system';

export const state = {
    loading: false,
    monitor: {},
    resources: {},
    service: service('system')
};
export const getters = {
    loading: function (state) {
        return !!state.loading;
    },
    monitor: function (state) {
        return JSON.parse(JSON.stringify(state.monitor));
    },
    resources: function (state) {
        return JSON.parse(JSON.stringify(state.resources));
    },
};
export const mutations = {
    'SET_LOADING': function (state, boolean = true) {
        state.loading = boolean;
    },
    'SET_RESOURCES': function (state, items) {
        state.resources = items;
    },
    'SET_MONITOR': function (state, items) {
        state.monitor = items;
    },
};

export const actions = {
    resourceMonitor: function ({commit, state}) {
        loading(commit)

        return state.service.resourceMonitor().then(response => {
            commit('SET_RESOURCES', response.resources);
            commit('SET_MONITOR', response.data);
            loading(commit, false)

            return response.data;
        }).catch((e) => {
            handleError(e, commit)
        })
    },
};
