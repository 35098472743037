import service from '@/api/orgPosition';
import {handleError} from '@/state/generators/helpers';

export const state = {
    service: service(),
}

export const getters = {}

export const mutations = {
    'UPDATE_CEO': function (state, ceo) {
        state.list = state.list?.map(item => {
            item.is_ceo = ceo.id === item.id;
            return item;
        }) || [];

        state.all = state.all?.map(item => {
            item.is_ceo = ceo.id === item.id;
            return item;
        }) || [];
    },
}

export const actions = {
    updateCeo: function ({commit, state}, data) {
        commit('app/SET_LOADING', true, {root: true})
        commit('SET_LOADING', true)

        return state.service.updateCeo(data).then(response => {
            commit('UPDATE_CEO', response.data)
            commit('app/SET_LOADING', false, {root: true})
            commit('SET_LOADING', false)
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        })
    }
}
