import axios from './axios'
import qs from "qs";

function service() {

    function fetchUserItems(id, data) {
        return axios.get(`users/${id}/customTargetValues`, { params: data, paramsSerializer: params => qs.stringify(params, { encode: false })  }).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function saveMany(data) {
        return axios.post('customTargetValues/updateOrCreate', data).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    return {
        fetchUserItems,
        saveMany
    }
}

export default service
