import service from '@/api/financialTargetValue';
import {loading, handleError} from '@/state/generators/helpers';
import {alert} from "../generators/helpers";

export const state = {
    service: service()
}

export const getters = {}

export const mutations = {}

export const actions = {
    userItems: function ({commit, state}, {id, query}) {
        loading(commit)

        return state.service.fetchUserItems(id, query).then(data => {
            commit('SET_ALL', data.data)
            loading(commit, false)
            return data
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    saveMany: function ({commit, state, getters}, items) {
        loading(commit)

        return state.service.saveMany(items).then(response => {
            commit('SET_ALL', response.data)
            alert(commit, this.$t('request.creation_is_successful'))
            commit('SET_ERRORS', {});

            loading(commit, false)

            return getters.all
        }).catch((e) => {
            handleError(e, commit)
        })
    },
}
