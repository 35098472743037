import service from '@/api/contact';
import {alert, handleError, loading} from "../generators/helpers";

export const state = {
    service: service('contacts')
}

export const mutations = {
    UPDATE_ITEM_DECISION_MAKER_FIELD: function (state, id) {
        const index = state.list.findIndex(listItem => listItem.id === id);
        const item = state.list[index];

        if (index !== -1) {
            item.decision_maker_exists = true;
            state.list[index] = item
            state.list = JSON.parse(JSON.stringify(state.list))
        }

        const allIndex = state.all.findIndex(allItem => allItem.id === item.id);
        const allTtem = state.all[allIndex];

        if (allIndex !== -1) {
            allTtem.decision_maker_exists = true;
            state.all[allIndex] = item;
            state.all = JSON.parse(JSON.stringify(state.all));
        }

        state.item.decision_maker_exists = true;
    },
}

export const getters = {

};

export const actions = {
    createDecisionMaker: function ({commit, state}, id) {
        loading(commit)

        return state.service.createDecisionMaker(id).then(response => {
            alert(commit, this.$t('request.creation_is_successful'))
            commit('UPDATE_ITEM_DECISION_MAKER_FIELD', id);
            commit('SET_ERRORS', {});
            loading(commit, false)
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        });
    },
}
