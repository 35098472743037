import service from '@/api/reminder';
import {loading, handleError} from '@/state/generators/helpers';

export const state = {
    ownLoading: false,
    ownPager: {},
    ownAdditional: {},
    own: [],
    mine: [],
    mineLoading: false,
    service: service()
}

export const getters = {
    ownLoading: function (state) {
        return !!state.ownLoading
    },
    ownPager: function (state) {
        return JSON.parse(JSON.stringify(state.ownPager))
    },
    own: function (state) {
        return state.own
    },
    mine: function (state) {
        return JSON.parse(JSON.stringify(state.mine))
    },
    mineLoading: function (state) {
        return !!state.mineLoading
    },
}

export const mutations = {
    'SET_OWN_LOADING': function (state, boolean = true) {
        state.ownLoading = boolean
    },

    'SET_OWN': function (state, list) {
        state.own = list
    },

    'SET_OWN_PAGER': function (state, pager = {}) {
        state.ownPager = pager
    },

    'SET_OWN_ADDITIONAL': function (state, data = {}) {
        state.ownAdditional = data
    },

    'SET_MINE_LOADING': function (state, boolean = true) {
        state.mineLoading = boolean
    },

    'SET_MINE': function (state, list) {
        state.mine = list
    },

    'REMOVE_MINE_ITEM': function (state, id) {
        const index = state.mine.findIndex(item => item.id === id);

        if (index !== -1) {
            state.mine.splice(index, 1)
        }
    },
}

export const actions = {
    own: function ({commit, state}, filters) {
        loading(commit)

        return state.service.fetchOwn(filters).then(response => {
            commit('SET_OWN', response.data)
            commit('SET_OWN_PAGER', response.meta)
            commit('SET_OWN_ADDITIONAL', response.additional)
            loading(commit, false)
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    mine: function ({commit, state}, {id, query}) {
        commit('app/SET_LOADING', true, {root: true})
        commit('SET_MINE_LOADING', true)

        return state.service.fetchMine(id, query).then(response => {
            commit('SET_MINE', response.data)
            commit('app/SET_LOADING', false, {root: true})
            commit('SET_MINE_LOADING', false)
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    markAsRead: function ({commit}, {reminder_id, user_id}) {
        commit('app/SET_LOADING', true, {root: true})
        commit('SET_MINE_LOADING', true)

        return state.service.markAsRead(reminder_id, user_id).then(response => {
            commit('REMOVE_MINE_ITEM', reminder_id);
            commit('app/SET_LOADING', false, {root: true})
            commit('SET_MINE_LOADING', false)
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        })
    },
}
