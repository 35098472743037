import axios from './axios'
import qs from "qs";

function service(endpoint) {

    function fetchGrouped(data) {
        return axios.get(endpoint, { params: data, paramsSerializer: params => qs.stringify(params, { encode: false })  }).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    return {
        fetchGrouped
    }
}

export default service
