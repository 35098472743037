import {
    createApp
} from "vue";
import App from "./App.vue";
import store from "./state/store";
import * as EvaIcons from "@stefandesu/eva-icons-vue"
import ActionCableVue from "actioncable-vue";
import BootstrapVue3 from 'bootstrap-vue-3';
import VueApexCharts from "vue3-apexcharts";
import vClickOutside from "click-outside-vue3"
import Maska from 'maska'
import axios from './api/axios'
import VueAxios from 'vue-axios'
import i18n from './translations/i18n'
import VueCropper from 'vue-cropperjs';
import VueTelInput from 'vue-tel-input';
import Swal from "sweetalert2";

import 'vue-tel-input/dist/vue-tel-input.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import '@vueform/slider/themes/default.css';
import './assets/scss/custom/plugins/mermaid.min.css'


import router from "./router";
import auth from "./auth"

import 'cropperjs/dist/cropper.css';
import "./assets/scss/app.scss";

window.Swal = Swal;

const websocketOptions = {
    debug: true, debugLevel: "error", connectionUrl: "http://localhost:8080/", connectImmediately: false
};

String.prototype.ucFirst = function () {
    if (!this) {
        return
    }

    if (this.length === 1) {
        return this.toUpperCase();
    } else {
        return this[0].toUpperCase() + this.substr(1);
    }
};

String.prototype.lcFirst = function () {
    if (!this) {
        return
    }

    if (this.length === 1) {
        return this.toLowerCase();
    } else {
        return this[0].toLowerCase() + this.substr(1);
    }
};

String.prototype.firstLetter = function () {
    if (!this) {
        return
    }

    return this[0];
};

String.prototype.firstLetters = function () {
    if (!this) {
        return
    }

    const words = this.split(' ').map(word => word[0]).join('')

    return words;
};

String.prototype.truncate = function (length) {
    if (!this || (this && this.length < length)) {
        return this
    }

    const text = this.substring(0, length)
    const index = text.lastIndexOf(' ')

    return text.substring(0, index) + ' ...';
};

String.prototype.rmValidation = function () {
    if (!this) {
        return
    }

    if (this.indexOf('(\\d+)') !== -1) {
        return this.replace(/\(\\d\+\)/g, '');
    } else if (this.indexOf('*') !== -1) {
        return this.replace(/\*/g, '');
    } else {
        return this;
    }
};

String.prototype.afterLast = function (character = '') {
    if (!this || !character) {
        return this;
    }

    const index = this.lastIndexOf(character) + 1;

    return this.substring(index).toLowerCase();
};

String.prototype.printDate = function (locale = 'hu-HU', options = {dateStyle: 'full', timeStyle: 'short'}) {
    if (!this) {
        return
    }

    const date = new Date(this);

    return date.toLocaleString(locale, options)
};

String.prototype.printTime = function () {
    if (!this) {
        return
    }

    const arr = this.split(':')

    if (!arr.length || arr.length === 1) {
        return this
    }

    return arr.slice(0, 2).join(':')
};

String.prototype.printCurrency = function (currency = 'HUF', locale = 'hu-HU') {
    let formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 0,
        currencyDisplay: 'narrowSymbol'
    });

    return formatter.format(this)
};

Number.prototype.printCurrency = function (currency = 'HUF', locale = 'hu-HU') {
    let formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 0,
        currencyDisplay: 'narrowSymbol',
        notation: 'compact'
    });

    return formatter.format(this)
};

String.prototype.printMonth = function (locale = 'hu-HU', options = {month: 'long'}) {
    if (!this) {
        return
    }

    const date = new Date(this);

    return date.toLocaleString(locale, options)
};

Number.prototype.printMonth = function (locale = 'hu-HU', options = {month: 'long'}) {
    if (!this) {
        return
    }

    const year = new Date().getFullYear();

    const date = new Date(year + '-' +this);

    return date.toLocaleString(locale, options)
};

Date.prototype.formattedDate = function () {
    const pad = (number) => {
        return number < 10 ? '0' + String(number) : number
    }

    return [this.getFullYear(), pad(this.getMonth()+1), pad(this.getDate())].join('-')
        + ' ' +
        [pad(this.getHours()), pad(this.getMinutes()), pad(this.getSeconds())].join(':');

};

const search = function (aliases, routes, parent) {
    return new Promise((resolve) => {
        routes.forEach(route => {
            if (!route.alias?.length) {
                route.alias = [];
            }

            if (aliases[route.path]) {
                route.alias.push(aliases[route.path]);
            }

            if (parent) {
                router.addRoute(parent, route);
            } else {
                router.addRoute(route);
            }

            if (route.children && route.children.length) {
                search(aliases, route.children, route.name);
            }
        })

        resolve(true);
    })
}

store.dispatch('trans/aliases').then(aliases => {
    search(aliases, router.options.routes).then(() => {
        createApp(App)
            .use(i18n)
            .use(store)
            .use(EvaIcons)
            .use(router)
            .use(BootstrapVue3)
            .use(VueApexCharts)
            .use(vClickOutside)
            .use(Maska)
            .use(ActionCableVue, websocketOptions)
            .use(VueAxios, axios)
            .use(auth)
            .use(VueTelInput)
            .directive('translate', {
                mounted: function (el, binding) {
                    if (!binding.value) {
                        return
                    }

                    let text = binding.instance.$t(binding.value)

                    if (binding.modifiers.ucFirst) {
                        text = text[0].toUpperCase() + text.slice(1)
                    }

                    if (binding.arg === 'placeholder') {
                        el.placeholder = text
                    } else {
                        el.innerText = text
                    }
                }
            })
            .component('VueCropper', VueCropper)
            .mount("#app");
    })
})
/*createApp(App)
    .use(i18n)
    .use(store)
//    .use(EvaIcons)
    .use(router)
    .use(BootstrapVue3)
    .use(VueApexCharts)
    .use(vClickOutside)
    .use(Maska)
    .use(ActionCableVue, websocketOptions)
    .use(VueAxios, axios)
    .use(auth)
    .use(VueTelInput)
    .directive('translate', {
        mounted: function (el, binding) {
            if (!binding.value) {
                return
            }

            let text = binding.instance.$t(binding.value)

            if (binding.modifiers.ucFirst) {
                text = text[0].toUpperCase() + text.slice(1)
            }

            if (binding.arg === 'placeholder') {
                el.placeholder = text
            } else {
                el.innerText = text
            }
        }
    })
    .component('VueCropper', VueCropper)
    .mount("#app");*/

