import service from '@/api/introduction';
import {handleError} from '@/state/generators/helpers';

export const state = {
    introduction: {},
    introductionLoading: false,
    service: service()
}

export const getters = {
    introduction: function (state) {
        return JSON.parse(JSON.stringify(state.introduction))
    },
    introductionLoading: function (state) {
        return !!state.introductionLoading
    },
}

export const mutations = {
    'SET_USER_INTRODUCTION_LOADING': function (state, boolean = true) {
        state.introductionLoading = boolean
    },

    'SET_USER_INTRODUCTION': function (state, item) {
        state.introduction = item
    }
}

export const actions = {
    userIntroduction: function ({commit, state}, id) {
        commit('app/SET_LOADING', true, {root: true})
        commit('SET_USER_INTRODUCTION_LOADING', true)

        return state.service.fetchUserIntroduction(id).then(response => {
            commit('SET_USER_INTRODUCTION', response.data)
            commit('SET_ITEM', response.data)
            commit('app/SET_LOADING', false, {root: true})
            commit('SET_USER_INTRODUCTION_LOADING', false)
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    clearUserIntroduction: function ({commit}) {
        commit('SET_USER_INTRODUCTION', {});
    },
}
