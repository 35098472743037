"use strict";

import axios from "axios";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

function objToFormData (obj = {}, formData = new FormData(), key = '') {
    if (!([Array, File, Object].includes(obj.constructor))) {
        return formData;
    }

    // Handle File recursive
    if (obj instanceof File) {
        formData.append(key, obj);
        return formData;
    }

    const bool = (value) => typeof value === 'boolean' ? (value === true ? 1 : 0) : value;

    for (const prop in obj) {
        // Validate value type
        if (obj[prop] && !([String, Number, Boolean, Array, Object, File].includes(obj[prop].constructor))) {
            continue;
        }

        // Set deep index of prop
        const deepKey = key ? key + `[${prop}]` : prop;

        // Handle array
        if (Array.isArray(obj[prop])) {
            if (obj[prop].length) {
                obj[prop].forEach((item, index) => {
                    if (typeof item === 'object') {
                        objToFormData(item, formData, `${deepKey}[${index}]`);
                    } else {
                        formData.append(deepKey + `[${index}]`, bool(item))
                    }
                });
                continue;
            } else {
                // formData.append(`${deepKey}[0]`, '')
                // continue;
            }
        }

        (typeof obj[prop] === 'object' && obj[prop] && obj[prop].constructor === Object)
            ? objToFormData(obj[prop], formData, deepKey) // Handle object
            : formData.append(deepKey, [undefined, null].includes(obj[prop]) ? '' : bool(obj[prop])) // Handle string, number, boolean
    }

    return formData;
}

const apiUrl = window.__env.api.url;

let config = {
    baseURL: apiUrl || process.env.baseURL || process.env.apiUrl || '',
    transformRequest: [function (data) {
        // return (data instanceof FormData ? data : objToFormData(data));
        if (this.method !== 'patch') {
            return (data instanceof FormData ? data : objToFormData(data));
        } else {
            let arrData = [];

            for (let prop in data) {
                arrData.push(prop + '=' + encodeURIComponent(data[prop]));
            }

            return arrData.join('&');
        }
    }],
    // timeout: 60 * 1000, // Timeout
    withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
    function(config) {
        // Do something before request is sent
        config.headers.Accept = 'application/json'
        if (localStorage.getItem('accessToken')) {
            config.headers.Authorization = 'Bearer ' + localStorage.getItem('accessToken')
        }
        return config;
    },
    function(error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Add a response interceptor
_axios.interceptors.response.use(
    function(response) {
        // Do something with response data
        if (response.data && response.data.access_token) {
            localStorage.setItem('accessToken', response.data.access_token)
        }
        return response;
    },
    function(error) {
        // Do something with response error
        return Promise.reject(error);
    }
);

export default _axios;
