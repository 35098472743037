import axios from './axios'
// import qs from "qs";

function service() {

    function updateAll(data = {}) {
        return axios.post(`organizations/positionSuperiors/all`, data).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    return {
        updateAll
    }
}

export default service
