import service from '@/api/notification';
import {handleError, loading} from "../generators/helpers";
import broadcast from '@/broadcast'

const generate = function () {
    return {
        state: {
            loading: false,
            list: [],
            unread: 0,
            errors: {},
            channels: [],
            count: 0,
            service: service('notifications')
        },

        mutations: {
            'SET_LOADING'(state, loading) {
                state.loading = loading
            },

            'SET_LIST'(state, list) {
                state.list = list
            },

            'SET_UNREAD'(state, number) {
                state.unread = number
            },

            'SET_COUNT'(state, number) {
                state.count = number
            },

            'DECREASE_UNREAD'(state) {
                state.unread--
            },

            'INCREASE_UNREAD'(state) {
                state.unread++
            },

            'SET_READ_ALL'(state) {
                state.unread = 0
                if (state.list && state.list.length) {
                    const date = new Date()
                    const padTo2Digits = (num) => {
                        return num.toString().padStart(2, '0')
                    }

                    state.list.forEach(item => {
                        item.read_at = `${date.getFullYear()}-${padTo2Digits(date.getMonth() + 1)}-${padTo2Digits(date.getDate())} ${padTo2Digits(date.getHours())}:${padTo2Digits(date.getMinutes())}:${padTo2Digits(date.getSeconds())}`
                    })
                }
            },

            'SET_ERRORS'(state, errors) {
                state.errors = errors
            },

            'APPEND_ITEMS'(state, items) {
                if (!items || !items.length) {
                    return
                }

                items.forEach(item => {
                    state.list.push(item)
                })
            },

            'PREPEND_LIST'(state, item) {
                if (!state.list && !state.list.length) {
                    state.list = [item]
                    state.unread++
                    state.count++
                    return
                }

                const idx = state.list.findIndex(x => x.id === item.id)

                if (idx === -1) {
                    state.list.unshift(item)
                    state.unread++
                    state.count++
                }
            },

            'UPDATE_IN_LIST'(state, id) {
                if (state.list && state.list.length > 0) {
                    const idx = state.list.findIndex(x => x.id === id)
                    const date = new Date()

                    if (idx !== -1) {
                        state.list[idx].read_at = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
                    }
                }
            },

            'ADD_CHANNEL'(state, name) {
                if (!state.channels.includes(name)) {
                    state.channels.push(name)
                }
            }
        },

        actions: {
            fetch({commit, getters, state, rootGetters}) {
                if (!rootGetters['user/authenticated']?.id || (getters.count === state.count && getters.count && state.count)) {
                    return
                }

                loading(commit)
                return state.service.fetch({offset: getters.count, limit: 5}).then(response => {
                    commit('SET_ERRORS', {});
                    if (!state.list.length) {
                        commit('SET_LIST', response.data)
                    } else {
                        commit('APPEND_ITEMS', response.data)
                    }
                    commit('SET_UNREAD', response.unread)
                    commit('SET_COUNT', response.count)
                    loading(commit, false)
                    return response.data;
                }).catch((e) => {
                    handleError(e, commit)
                })
            },

            subscribe({state, rootGetters, commit}) {
                const id = rootGetters['user/authenticated'].id

                if (!id) {
                    return
                }

                const channel = 'users.' + id
                if (state.channels.includes(channel)) {
                    return
                }

                broadcast.private(channel).notification((notification) => {
                    commit('PREPEND_LIST', notification)
                    const audio = new Audio(require('@/assets/audio/notification.mp3'))
                    audio.play()
                })
            },

            markAsRead({commit}, id) {
                loading(commit)
                return state.service.markAsRead(id).then(response => {
                    commit('SET_ERRORS', {});
                    commit('UPDATE_IN_LIST', id)
                    commit('DECREASE_UNREAD')
                    loading(commit, false)
                    return response.data;
                }).catch((e) => {
                    handleError(e, commit)
                })
            },

            markAsReadAll({commit, state}) {
                loading(commit)
                return state.service.markAsReadAll().then(response => {
                    commit('SET_ERRORS', {});
                    commit('SET_READ_ALL');
                    loading(commit, false)
                    return response.data;
                }).catch((e) => {
                    handleError(e, commit)
                })
            }
        },

        getters: {
            list: state => JSON.parse(JSON.stringify(state.list)),
            loading: state => state.loading,
            errors: state => state.errors,
            unread: state => state.unread,
            count: state => state.list.length,
            allLoaded: state => state.count <= state.list.length,
            allRead: state => state.list?.every(noti => noti.read_at)
        },

        namespaced: true,
    }
}

const module = generate()

export const state = module.state
export const getters = module.getters
export const mutations = module.mutations
export const actions = module.actions
