import {alert, handleError, loading} from "../generators/helpers";

export const state = {
    item: {},
}

export const mutations = {
    'ADD_PRICE': function (state, price) {
        if (!Array.isArray(state.item.prices)) {
            state.item.prices = [];
        }
        state.item.prices.push(price)
    },

    'REMOVE_PRICE': function (state, id) {
        const index = state.item.prices.findIndex(item => item.id === id)

        if (index !== -1) {
            state.item.prices.splice(index, 1);
        }
    },

    'UPDATE_PRICE': function (state, price) {
        const index = state.item.prices.findIndex(item => item.id === price.id)

        if (index !== -1) {
            Object.keys(price).forEach((key) => {
                state.item.prices[index][key] = price[key]
            })
        }
    },

    'UPDATE_ACTIVE': function (state, update) {
        const index = state.item.prices.findIndex(item => item.id === update.id)

        if (index !== -1) {
            state.item.prices = state.item.prices.map((price, priceIndex) => {
                price.is_active = !(index !== priceIndex && update.currency_id === price.currency_id);

                return price
            });
        }
    },
}

export const actions = {
    addPrice: function ({commit}, price) {
        commit('ADD_PRICE', price)
    },

    removePrice: function ({commit}, id) {
        commit('REMOVE_PRICE', id)
    },

    updatePrice: function ({commit}, price) {
        commit('UPDATE_PRICE', price)
    },

    activatePrice: function ({commit, rootState }, price) {
        loading(commit)

        const id = price.id
        const data = {is_active: true, _method: 'PUT'}

        return rootState.price.service.update(id, data).then(response => {
            const model = Object.assign({}, data, response.data)

            commit('REPLACE_ITEM', model);
            commit('UPDATE_ACTIVE', model);
            alert(commit, this.$t('request.save_is_successful'))
            commit('SET_ERRORS', {});
            loading(commit, false)

            return model;
        }).catch((e) => {
            handleError(e, commit)
        })
    },
}
