import axios from './axios'
import qs from "qs";

function service(endpoint) {

    function fetchAuthenticated() {
        return axios.get(`${endpoint}/authenticated`).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function fetchProfile(id) {
        return axios.get(`profiles/${id}`).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function changePassword (id, passwords) {
        return axios.post(`${endpoint}/${id}/change-password`, passwords).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function syncRoles (id, data) {
        return axios.post(`${endpoint}/${id}/roles`, data).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function syncRoleGroups (id, data) {
        return axios.post(`${endpoint}/${id}/roleGroups`, data).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function fetchImplementers(data) {
        return axios.get(`${endpoint}/implementers`, { params: data, paramsSerializer: params => qs.stringify(params, { encode: false })  }).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function fetchPotentialSuperiors(id) {
        return axios.get(`${endpoint}/${id}/potential-superiors`).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function fetchPotentialSubordinates(id) {
        return axios.get(`${endpoint}/${id}/potential-subordinates`).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function fetchSubordinates(id) {
        return axios.get(`${endpoint}/${id}/subordinates`).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function fetchSuperior(id) {
        return axios.get(`${endpoint}/${id}/superior`).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function syncSubordinates(id, data) {
        return axios.post(`${endpoint}/${id}/subordinates`, data).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function checkSubordinate(id) {
        return axios.get(`me/is-subordinate/${id}`).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function search(data) {
        return axios.get(`${endpoint}/search`, { params: data, paramsSerializer: params => qs.stringify(params, { encode: false })  }).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function fetchPositions(id, data = {}) {
        return axios.get(`organizations/users/${id}/positions`, { params: data, paramsSerializer: params => qs.stringify(params, { encode: false })  }).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function updatePositions(id, data = {}) {
        return axios.post(`organizations/users/${id}/positions`, data).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function fetchSkills(id, data = {}) {
        return axios.get(`organizations/users/${id}/skills`, { params: data, paramsSerializer: params => qs.stringify(params, { encode: false })  }).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function updateSkills(id, data = {}) {
        return axios.post(`organizations/users/${id}/skills`, data).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function fetchTasks(id, data = {}) {
        return axios.get(`users/${id}/tasks`, { params: data, paramsSerializer: params => qs.stringify(params, { encode: false })  }).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function fetchClosedTasks(id, data = {}) {
        return axios.get(`users/${id}/closed`, { params: data, paramsSerializer: params => qs.stringify(params, { encode: false })  }).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function fetchCalendar(id, data = {}) {
        return axios.get(`users/${id}/calendar`, { params: data, paramsSerializer: params => qs.stringify(params, { encode: false })  }).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    return {
        fetchProfile,
        fetchAuthenticated,
        changePassword,
        syncRoles,
        syncRoleGroups,
        fetchImplementers,
        fetchPotentialSuperiors,
        fetchSubordinates,
        syncSubordinates,
        fetchPotentialSubordinates,
        checkSubordinate,
        fetchSuperior,
        search,
        fetchPositions,
        updatePositions,
        fetchSkills,
        updateSkills,
        fetchTasks,
        fetchClosedTasks,
        fetchCalendar
    }
}

export default service
