import service from '@/api/relativeFormat';
import {loading, handleError} from '@/state/generators/helpers';

export const state = {
    groupedLoading: false,
    groupedPager: {},
    groupedAdditional: {},
    grouped: [],
    service: service('relativeFormats/grouped')
}

export const getters = {
    groupedLoading: function (state) {
        return !!state.groupedLoading
    },
    groupedPager: function (state) {
        return JSON.parse(JSON.stringify(state.groupedPager))
    },
    grouped: function (state) {
        return state.grouped
    },
}

export const mutations = {
    'SET_GROUPED_LOADING': function (state, boolean = true) {
        state.groupedLoading = boolean
    },

    'SET_GROUPED': function (state, list) {
        state.grouped = list
    },

    'SET_GROUPED_PAGER': function (state, pager = {}) {
        state.groupedPager = pager
    },

    'SET_GROUPED_ADDITIONAL': function (state, data = {}) {
        state.groupedAdditional = data
    },
}

export const actions = {
    grouped: function ({commit, state}, filters) {
        loading(commit)

        return state.service.fetchGrouped(filters).then(response => {
            commit('SET_GROUPED', response.data)
            commit('SET_GROUPED_PAGER', response.meta)
            commit('SET_GROUPED_ADDITIONAL', response.additional)
            loading(commit, false)
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        })
    },
}
