import service from '@/api/googleAccessToken';
import {loading, handleError} from '@/state/generators/helpers';

export const state = {
    loading: false,
    item: {},
    service: service('google/accessTokens'),
}

export const getters = {
    item: (state) => {
        return JSON.parse(JSON.stringify(state.item))
    },

    loading: (state) => {
        return state.loading
    }
}

export const mutations = {
    'SET_LOADING': function (state, boolean = true) {
        state.loading = boolean
    },

    'SET_ITEM': function (state, token) {
        state.item = token
    }
}

export const actions = {
    request: function ({commit, state, rootGetters, dispatch}, data) {
        loading(commit)

        return state.service.request(data).then(data => {
            commit('SET_ITEM', data.data)

            const user = rootGetters['user/authenticated']

            user.google_api_access_token = data.data.access_token

            dispatch('user/setAuthenticated', user, { root: true });

            loading(commit, false)
            return data
        }).catch((e) => {
            handleError(e, commit)
        })
    }
}
