import axios from './axios'

function accessTokenService(endpoint) {

    function request(data) {
        return axios.post(`${endpoint}/request`, data).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    return {
        request
    }
}

export default accessTokenService
