import apiServiceGenerator from "@/api/sortableGenerator";
import {loading, alert, handleError} from './helpers'

export default function (endpoint) {

    return {
        state: {
            loading: false,
            list: [],
            service: apiServiceGenerator(endpoint)
        },

        getters: {
            sorting: function (state) {
                return state.list.map((item, index) => {
                    return {id: item.id, sort: index + 1}
                })
            },
        },

        mutations: {
            'SORT_LIST': function (state, list) {
                const sorted = []

                list.forEach((item) => {
                    sorted.push(state.list.find(orig => orig.id === item.id))
                })

                state.list = sorted
            },

            'SET_LIST': function (state, list) {
                state.list = list
            },
        },

        actions: {
            sortList: function ({commit}, array = []) {
                commit('SORT_LIST', array)
            },

            saveSorting: function ({ state, getters, commit }, items = []) {
                loading(commit)
                const data = items?.length
                    ? items.map((item, index) => {
                        return {id: item.id, sort: index + 1}
                    })
                    : getters.sorting;

                state.service.updateSorting(data).then(response => {
                    alert(commit, this.$t('request.save_is_successful'))
                    commit('SET_LIST', response.data)
                    commit('SET_ERRORS', {});
                    loading(commit, false)
                    return response;
                }).catch((e) => {
                    handleError(e, commit)
                });
            },
        }
    }
}
