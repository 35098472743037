import service from '@/api/performance';
import {handleError, loading} from "../generators/helpers";

export const state = {
    service: service('performances'),
    me: {},
    personalPerformanceWithTargetValues: {},
    personalPerformance: {},
    subordinates: {},
    interactions: [],
    weekly: {},
    monthly: {},
    yearly: {},
    summary: {},
    subordinatesLoading: false,
    weeklyLoading: false,
    monthlyLoading: false,
    yearlyLoading: false,
    summaryLoading: false,
}

export const mutations = {
    'SET_ME': function (state, me = {}) {
        state.me = me
    },
    'SET_PERSONAL_PERFORMANCE_WITH_TARGET_VALUE': function (state, {id, value, years, months, target_values}) {
        state.personalPerformanceWithTargetValues[id] = {data: value, years: years, months: months, target_values: target_values}
    },
    'SET_PERSONAL_PERFORMANCE': function (state, {id, value, years, months, target_values}) {
        state.personalPerformance[id] = {data: value, years: years, months: months, target_values: target_values}
    },
    'SET_SUBORDINATES': function (state, {id, value}) {
        state.subordinates[id] = value;
    },
    'SET_SUBORDINATES_LOADING': function (state, boolean = false) {
        state.subordinatesLoading = boolean;
    },
    'SET_WEEKLY_LOADING': function (state, loading = false) {
        state.weeklyLoading = loading;
    },
    'SET_MONTHLY_LOADING': function (state, loading = false) {
        state.monthlyLoading = loading;
    },
    'SET_YEARLY_LOADING': function (state, loading = false) {
        state.yearlyLoading = loading;
    },
    'SET_WEEKLY': function (state, data = {}) {
        state.weekly = data;
    },
    'SET_MONTHLY': function (state, data = {}) {
        state.monthly = data;
    },
    'SET_YEARLY': function (state, data = {}) {
        state.yearly = data;
    },
    'CLEAR_WEEKLY': function (state) {
        state.weekly = {};
        state.weeklyLoading = false;
    },
    'CLEAR_MONTHLY': function (state) {
        state.monthly = {};
        state.monthlyLoading = false;
    },
    'CLEAR_YEARLY': function (state) {
        state.yearly = {};
        state.yearlyLoading = false;
    },
    'SET_SUMMARY_LOADING': function (state, boolean = false) {
        state.summaryLoading = boolean;
    },
    'SET_SUMMARY': function (state, data = {}) {
        state.summary = data;
    },
    'SET_INTERACTIONS': function (state, data = []) {
        state.interactions = data;
    },
}

export const getters = {
    me: state => JSON.parse(JSON.stringify(state.me)),
    weeklyLoading: (state) => {
        return !!state.weeklyLoading;
    },
    monthlyLoading: (state) => {
        return !!state.monthlyLoading;
    },
    yearlyLoading: (state) => {
        return !!state.yearlyLoading;
    },
    summaryLoading: (state) => {
        return !!state.summaryLoading;
    },
    subordinatesLoading: (state) => {
        return !!state.subordinatesLoading;
    },
    weekly: (state) => {
        return JSON.parse(JSON.stringify(state.weekly));
    },
    monthly: (state) => {
        return JSON.parse(JSON.stringify(state.monthly));
    },
    yearly: (state) => {
        return JSON.parse(JSON.stringify(state.yearly));
    },
    years: (state) => {
        return Object.keys(state.yearly || {});
    },
    summary: (state) => {
        return JSON.parse(JSON.stringify(state.summary));
    },
    interactions: (state) => {
        return JSON.parse(JSON.stringify(state.interactions));
    },
    subordinates: (state) => (id) => {
        return JSON.parse(JSON.stringify(state.subordinates[id] || []));
    }
};

export const actions = {
    me: function ({commit, state}, filters) {
        loading(commit)

        return state.service.me(filters).then(response => {
            commit('SET_ME', response.data);
            commit('SET_ERRORS', {});
            loading(commit, false);
            return response.data;
        }).catch((e) => {
            handleError(e, commit)
        });
    },

    personalPerformanceWithTargetValues: function ({commit, state}, id) {
        loading(commit)

        /*if (state.personalPerformanceWithTargetValues[id]) {
            loading(commit, false);
            return state.personalPerformanceWithTargetValues[id]
        }*/

        return state.service.personalPerformanceWithTargetValues(id).then(response => {
            commit('SET_PERSONAL_PERFORMANCE_WITH_TARGET_VALUE', {id: id, value: response.data, years: response.years, months: response.months});
            commit('SET_ERRORS', {});
            loading(commit, false);
            return {data: response.data, years: response.years, months: response.months};
        }).catch((e) => {
            handleError(e, commit)
        });
    },

    personalPerformance: function ({commit, state}, id) {
        loading(commit)

        /*if (state.personalPerformance[id]) {
            loading(commit, false);
            return JSON.parse(JSON.stringify(state.personalPerformance[id]))
        }*/

        return state.service.personalPerformance(id).then(response => {

            commit('SET_PERSONAL_PERFORMANCE', {id: id, value: response.data, years: response.years, months: response.months, target_values: response.target_values});
            commit('SET_ERRORS', {});
            loading(commit, false);
            return JSON.parse(JSON.stringify({data: response.data, years: response.years, months: response.months, target_values: response.target_values}));
        }).catch((e) => {
            handleError(e, commit)
        });
    },

    subordinates: function ({commit, state}, id) {
        commit('app/SET_LOADING', true, {root: true});
        commit('SET_SUBORDINATES_LOADING', true);

        /*if (state.subordinates[id]) {
            loading(commit, false);
            return state.subordinates[id]
        }*/

        return state.service.subordinates(id).then(response => {
            commit('SET_SUBORDINATES', {id: id, value: response.users, years: response.years, months: response.months});
            commit('SET_INTERACTIONS', response.interactions);
            commit('SET_ERRORS', {});
            commit('app/SET_LOADING', false, {root: true});
            commit('SET_SUBORDINATES_LOADING', false);
            return {users: response.users, years: response.years, interactions: response.interactions};
        }).catch((e) => {
            handleError(e, commit)
        });
    },

    weekly: function ({commit, state}, id) {
        commit('app/SET_LOADING', true, {root: true});
        commit('SET_WEEKLY_LOADING', true);

        return state.service.weekly(id).then(response => {
            commit('SET_WEEKLY', response);
            commit('SET_ERRORS', {});
            commit('app/SET_LOADING', false, {root: true});
            commit('SET_WEEKLY_LOADING', false);
            return response;
        }).catch((e) => {
            handleError(e, commit)
        });
    },

    monthly: function ({commit, state}, id) {
        commit('app/SET_LOADING', true, {root: true});
        commit('SET_MONTHLY_LOADING', true);

        return state.service.monthly(id).then(response => {
            commit('SET_MONTHLY', response);
            commit('SET_ERRORS', {});
            commit('app/SET_LOADING', false, {root: true});
            commit('SET_MONTHLY_LOADING', false);
            return response;
        }).catch((e) => {
            handleError(e, commit)
        });
    },

    yearly: function ({commit, state}, id) {
        commit('app/SET_LOADING', true, {root: true});
        commit('SET_YEARLY_LOADING', true);

        return state.service.yearly(id).then(response => {
            commit('SET_YEARLY', response);
            commit('SET_ERRORS', {});
            commit('app/SET_LOADING', false, {root: true});
            commit('SET_YEARLY_LOADING', false);
            return response;
        }).catch((e) => {
            handleError(e, commit)
        });
    },

    summary: function ({commit, state}, id) {
        commit('app/SET_LOADING', true, {root: true});
        commit('SET_SUMMARY_LOADING', true);

        return state.service.summary(id).then(response => {
            commit('SET_SUMMARY', response);
            commit('SET_ERRORS', {});
            commit('app/SET_LOADING', false, {root: true});
            commit('SET_SUMMARY_LOADING', false);
            return response;
        }).catch((e) => {
            handleError(e, commit)
        });
    },

    clearWeekly: function ({commit}) {
        commit('CLEAR_WEEKLY');
    },

    clearMonthly: function ({commit}) {
        commit('CLEAR_MONTHLY');
    },

    clearYearly: function ({commit}) {
        commit('CLEAR_YEARLY');
    },
}
