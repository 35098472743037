import service from '@/api/offer';
import {loading, handleError} from '@/state/generators/helpers';
import {alert} from "../generators/helpers";

export const state = {
    loading: false,
    pager: {},
    additional: {},
    list: [],
    own: [],
    service: service('offers/own')
}

export const getters = {
    loading: function (state) {
        return !!state.loading
    },
    pager: function (state) {
        return JSON.parse(JSON.stringify(state.pager))
    },
    list: function (state) {
        return state.list
    },
    own: function (state) {
        return state.own
    }
}

export const mutations = {
    'SET_LOADING': function (state, boolean = true) {
        state.loading = boolean
    },

    'SET_LIST': function (state, list) {
        state.list = list
    },

    'SET_OWN': function (state, list) {
        state.own = list
    },

    'SET_PAGER': function (state, pager = {}) {
        state.pager = pager
    },

    'SET_ADDITIONAL': function (state, data = {}) {
        state.additional = data
    },

    'UPDATE_ITEM_AMOUNT': function (state, amount) {
        state.item.amount = amount
    },
}

export const actions = {
    own: function ({commit, state}, filters) {
        loading(commit)

        return state.service.fetchOwn(filters).then(response => {
            commit('SET_LIST', response.data)
            commit('SET_OWN', response.data)
            commit('SET_PAGER', response.meta)
            commit('SET_ADDITIONAL', response.additional)
            loading(commit, false)
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    freshAmount: function ({commit}, amount) {
        commit('UPDATE_ITEM_AMOUNT', amount)
    },

    accept: function ({commit}, id) {
        loading(commit)

        return state.service.accept(id).then(response => {
            const model = Object.assign({}, response.data)

            commit('REPLACE_ITEM', model);
            commit('SET_ITEM', model);
            alert(commit, this.$t('request.save_is_successful'))
            commit('SET_ERRORS', {});
            loading(commit, false)

            return model;
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    reject: function ({commit}, id) {
        loading(commit)

        return state.service.reject(id).then(response => {
            const model = Object.assign({}, response.data)

            commit('REPLACE_ITEM', model);
            commit('SET_ITEM', model);
            alert(commit, this.$t('request.save_is_successful'))
            commit('SET_ERRORS', {});
            loading(commit, false)

            return model;
        }).catch((e) => {
            handleError(e, commit)
        })
    },
}
