import {handleError} from './helpers'

export default function () {
    return {
        state: {
            autocompleteLoading: false,
            autocompleteList: [],
            autocompleteFilters: {},
            autocompletePager: {},
        },
        getters: {
            autocompleteList: (state) => {
                return JSON.parse(JSON.stringify(state.autocompleteList))
            },

            autocompleteLoading: (state) => {
                return !!state.autocompleteLoading
            },

            autocompleteEnd: (state) => {
                return state.autocompletePager?.total === state.autocompleteList?.length
            },
        },
        mutations: {
            'SET_AUTOCOMPLETE_LIST': function (state, list = []) {
                state.autocompleteList = list
            },

            'ADD_TO_AUTOCOMPLETE_LIST': function (state, list = []) {
                state.autocompleteList = [...state.autocompleteList, ...list]
            },

            'SET_LOADING_AUTOCOMPLETE': function (state, loading) {
                state.autocompleteLoading = loading
            },

            'SET_AUTOCOMPLETE_FILTERS': function (state, filters) {
                state.autocompleteFilters = filters;
            },

            'SET_AUTOCOMPLETE_PAGER': function (state, pager) {
                state.autocompletePager = pager;
            },
        },
        actions: {
            autocomplete: function ({commit, state}, filters) {
                commit('app/SET_LOADING', true, {root: true})
                commit('SET_LOADING_AUTOCOMPLETE', true)

                const data = Object.assign({page: 1}, filters)

                commit('SET_AUTOCOMPLETE_FILTERS', data)

                const method = state.service.autocomplete

                return method(data).then(response => {
                    commit('SET_AUTOCOMPLETE_LIST', response.data)
                    commit('SET_AUTOCOMPLETE_PAGER', response.meta)
                    commit('app/SET_LOADING', false, {root: true})
                    commit('SET_LOADING_AUTOCOMPLETE', false)
                    return response.data
                }).catch((e) => {
                    handleError(e, commit)
                    commit('SET_AUTOCOMPLETE_FILTERS', {})
                })
            },

            moreAutocomplete: function ({commit, state}) {
                commit('app/SET_LOADING', true, {root: true})
                commit('SET_LOADING_AUTOCOMPLETE', true)

                const data = Object.assign({}, state.autocompleteFilters, {page: (state.autocompletePager?.current_page || 1) + 1})

                const method = state.service.autocomplete

                return method(data).then(response => {
                    commit('ADD_TO_AUTOCOMPLETE_LIST', response.data)
                    commit('SET_AUTOCOMPLETE_PAGER', response.meta)
                    commit('app/SET_LOADING', false, {root: true})
                    commit('SET_LOADING_AUTOCOMPLETE', false)
                    return response.data
                }).catch((e) => {
                    handleError(e, commit)
                })
            },

            clearAutocomplete: function ({commit}) {
                commit('SET_AUTOCOMPLETE_FILTERS', {})
                commit('SET_AUTOCOMPLETE_LIST', [])
                commit('SET_AUTOCOMPLETE_PAGER', {})
            }
        }
    }
}
