import axios from './axios'
// import qs from "qs";

function service(endpoint) {
    function resourceMonitor() {
        return axios.get(`${endpoint}/resource-monitor`).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    return {
        resourceMonitor,
    }
}

export default service
