import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

window.Pusher = Pusher;

const echo = new Echo({
    broadcaster: 'pusher',
    host: window.__env.broadcast.url,
    key: `${process.env.VUE_APP_PUSHER_KEY}`,
    cluster: `${process.env.VUE_APP_PUSHER_CLUSTER}`,
    forceTLS: `${process.env.VUE_APP_PUSHER_TLS}`,
    authEndpoint: window.__env.broadcast.auth,
    auth: {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken')
        }
    }
});

window.Echo = echo;

export default echo;
