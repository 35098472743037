import service from '@/api/model';
import {handleError} from '@/state/generators/helpers';

export const state = {
    loading: false,
    relations: [],
    service: service('models/relations')
}

export const getters = {
    loading: (state) => {
        return !!state.loading;
    },

    relations: (state) => {
        return JSON.parse(JSON.stringify(state.relations))
    }
}

export const mutations = {
    'SET_LOADING': function (state, boolean = true) {
        state.loading = boolean
    },

    'SET_RELATIONS': function (state, arr) {
        state.relations = arr
    }
}

export const actions = {
    relations: function ({ commit }, {model, data}) {
        commit('app/SET_LOADING', true, {root: true})
        commit('SET_LOADING', true)

        return state.service.relations(model, data).then(data => {
            commit('SET_RELATIONS', data.data)
            commit('app/SET_LOADING', false, {root: true})
            commit('SET_LOADING', false)
            return data
        }).catch((e) => {
            handleError(e, commit)
        })
    },


    clearModule: function ({commit}) {
        commit('SET_RELATIONS', [])
    },
}
