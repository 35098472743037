import axios from './axios'
import qs from "qs";

function service() {

    function fetchOwn(data) {
        return axios.get('meetings/own', { params: data, paramsSerializer: params => qs.stringify(params, { encode: false })  }).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function fetchMine(id, data) {
        return axios.get(`users/${id}/meetings`, { params: data, paramsSerializer: params => qs.stringify(params, { encode: false })  }).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function fetchProjectMeetings(id, data = {}) {
        return axios.get(`projects/${id}/meetings`, { params: data, paramsSerializer: params => qs.stringify(params, { encode: false })  }).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function cancel(id) {
        return axios.post(`meetings/${id}/cancel`).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function realize(id, data = {}) {
        return axios.post(`meetings/${id}/realize`, data).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    return {
        fetchOwn,
        fetchMine,
        fetchProjectMeetings,
        cancel,
        realize
    }
}

export default service
