import service from '@/api/partner';
import {alert, handleError, loading} from "../generators/helpers";

export const state = {
    service: service('partners'),
    projects: [],
    projectsLoading: false,
    filterCount: 0,
    ownLoading: false,
    ownPager: {},
    ownAdditional: {},
    accessibleLoading: false,
    accessiblePager: {},
    accessibleAdditional: {},
    own: [],
    accessible: [],
}

export const mutations = {
    'SET_PROJECTS_LOADING': function (state, boolean = true) {
        state.projectsLoading = boolean
    },

    'SET_PROJECTS': function (state, list) {
        state.projects = list
    },

    'SET_FILTER_COUNT': function (state, count = 0) {
        state.filterCount = count
    },

    'SET_OWN_LOADING': function (state, boolean = true) {
        state.ownLoading = boolean
    },

    'SET_OWN': function (state, list) {
        state.own = list
    },

    'SET_OWN_PAGER': function (state, pager = {}) {
        state.ownPager = pager
    },

    'SET_OWN_ADDITIONAL': function (state, data = {}) {
        state.ownAdditional = data
    },

    'REMOVE_FROM_OWN': function (state, item) {
        const index = state.own.findIndex(own => own.id === item.id)

        if (index !== -1) {
            state.own.splice(index, 1);
        }
    },

    'SET_ACCESSIBLE_LOADING': function (state, boolean = true) {
        state.accessibleLoading = boolean
    },

    'SET_ACCESSIBLE': function (state, list) {
        state.accessible = list
    },

    'SET_ACCESSIBLE_PAGER': function (state, pager = {}) {
        state.accessiblePager = pager
    },

    'SET_ACCESSIBLE_ADDITIONAL': function (state, data = {}) {
        state.accessibleAdditional = data
    },
}

export const getters = {
    filterCount: state => Number(state.filterCount),
    projectsLoading: function (state) {
        return !!state.projectsLoading
    },
    projects: function (state) {
        return state.projects
    },
    ownLoading: function (state) {
        return !!state.ownLoading
    },
    ownPager: function (state) {
        return JSON.parse(JSON.stringify(state.ownPager))
    },
    own: function (state) {
        return state.own
    },
    accessibleLoading: function (state) {
        return !!state.accessibleLoading
    },
    accessiblePager: function (state) {
        return JSON.parse(JSON.stringify(state.accessiblePager))
    },
    accessible: function (state) {
        return state.accessible
    },
};

export const actions = {
    merge: function ({commit, state}, {partner_id, ceasing_id, data}) {
        loading(commit)

        if (!partner_id || !ceasing_id || !data) {
            return null;
        }

        return state.service.merge(partner_id, ceasing_id, data).then(response => {
            commit('REPLACE_ITEM', response.data);
            commit('REMOVE_ITEM', ceasing_id);
            alert(commit, this.$t('request.save_is_successful'))
            commit('SET_ERRORS', {});
            loading(commit, false)
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        });
    },

    filterCount: function ({commit, state}, filters) {
        return state.service.filterCount(filters).then(response => {
            commit('SET_FILTER_COUNT', response.data);
            commit('SET_ERRORS', {});
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        });
    },

    own: function ({commit, state}, filters) {
        loading(commit)

        return state.service.fetchOwn(filters).then(response => {
            commit('SET_OWN', response.data)
            commit('SET_OWN_PAGER', response.meta)
            commit('SET_OWN_ADDITIONAL', response.additional)
            loading(commit, false)
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    projects: function ({commit, state}, id) {
        commit('app/SET_LOADING', true, {root: true})
        commit('SET_PROJECTS_LOADING', true)

        return state.service.fetchProjects(id).then(response => {
            commit('SET_PROJECTS', response.data)
            commit('app/SET_LOADING', false, {root: true})
            commit('SET_PROJECTS_LOADING', false)
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    clearProjects: function ({commit}) {
        commit('SET_PROJECTS', []);
    },

    accessible: function ({commit, state}, filters) {
        loading(commit)

        return state.service.fetchAccessible(filters).then(response => {
            commit('SET_ACCESSIBLE', response.data)
            commit('SET_ACCESSIBLE_PAGER', response.meta)
            commit('SET_ACCESSIBLE_ADDITIONAL', response.additional)
            loading(commit, false)
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        })
    },
}
