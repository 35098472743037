import axios from './axios'
import qs from "qs";

function service() {

    function fetchOwn(data) {
        return axios.get('reminders/own', { params: data, paramsSerializer: params => qs.stringify(params, { encode: false })  }).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function fetchMine(id, data) {
        return axios.get(`users/${id}/reminders`, { params: data, paramsSerializer: params => qs.stringify(params, { encode: false })  }).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function markAsRead(reminder_id, user_id) {
        return axios.post(`users/${user_id}/reminders/${reminder_id}`).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    return {
        fetchOwn,
        fetchMine,
        markAsRead
    }
}

export default service
