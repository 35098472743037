import service from '@/api/department';
import {handleError} from '@/state/generators/helpers';

export const state = {
    pivots: [],
    leader: {},
    allMember: [],
    members: [],
    memberLoading: false,
    leaderLoading: false,
    service: service(),
    locations: [],
    locationPivots: [],
    locationLoading: false
}

export const getters = {
    memberLoading: function (state) {
        return !!state.memberLoading
    },
    leaderLoading: function (state) {
        return !!state.leaderLoading
    },
    leader: function (state) {
        return JSON.parse(JSON.stringify(state.leader))
    },
    allMember: function (state) {
        return JSON.parse(JSON.stringify(state.allMember))
    },
    members: function (state) {
        return JSON.parse(JSON.stringify(state.members))
    },
    pivots: function (state) {
        return JSON.parse(JSON.stringify(state.pivots))
    },
    locationLoading: function (state) {
        return !!state.locationLoading
    },
    locations: function (state) {
        return JSON.parse(JSON.stringify(state.locations))
    },
    locationPivots: function (state) {
        return JSON.parse(JSON.stringify(state.locationPivots))
    },
}

export const mutations = {
    'SET_ALL_MEMBER': function (state, data = []) {
        state.allMember = data
    },

    'SET_MEMBER_LOADING': function (state, boolean = true) {
        state.memberLoading = boolean
    },

    'SET_LEADER_LOADING': function (state, boolean = true) {
        state.leaderLoading = boolean
    },

    'SET_PIVOTS': function (state, list = []) {
        state.pivots = list
    },

    'SET_LEADER': function (state, model = {}) {
        state.leader = model
    },

    'SET_MEMBERS': function (state, list = []) {
        state.members = list
    },

    'SET_LOCATION_LOADING': function (state, boolean = true) {
        state.locationLoading = boolean
    },

    'SET_LOCATIONS': function (state, list = []) {
        state.locations = list
    },

    'SET_LOCATION_PIVOTS': function (state, list = []) {
        state.locationPivots = list
    },
}

export const actions = {
    members: function ({commit, state}, id) {
        commit('app/SET_LOADING', true, {root: true})
        commit('SET_MEMBER_LOADING', true)

        return state.service.fetchMembers(id).then(response => {
            commit('SET_ALL_MEMBER', response.data)
            commit('SET_MEMBERS', response.members)
            commit('SET_LEADER', response.leader)
            commit('SET_PIVOTS', response.pivots)
            commit('app/SET_LOADING', false, {root: true})
            commit('SET_MEMBER_LOADING', false)
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    updateLeader: function ({commit, state, dispatch}, {id, leader_id}) {
        commit('app/SET_LOADING', true, {root: true})
        commit('SET_LEADER_LOADING', true)

        return state.service.updateLeader(id, {leader_id: leader_id}).then(response => {
            commit('SET_LEADER', response.data)
            commit('app/SET_LOADING', false, {root: true})
            commit('SET_LEADER_LOADING', false)
            dispatch('members', id)
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    sync: function ({commit, state}, {id, pivots}) {
        commit('app/SET_LOADING', true, {root: true})
        commit('SET_MEMBER_LOADING', true)

        return state.service.updateMembers(id, {pivots: pivots}).then(response => {
            commit('SET_ALL_MEMBER', response.data)
            commit('SET_MEMBERS', response.members)
            commit('SET_LEADER', response.leader)
            commit('SET_PIVOTS', response.pivots)
            commit('app/SET_LOADING', false, {root: true})
            commit('SET_MEMBER_LOADING', false)
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    locations: function ({commit, state}, id) {
        commit('app/SET_LOADING', true, {root: true})
        commit('SET_LOCATION_LOADING', true)

        return state.service.fetchLocations(id).then(response => {
            commit('SET_LOCATIONS', response.data)
            commit('SET_LOCATION_PIVOTS', response.pivots)
            commit('app/SET_LOADING', false, {root: true})
            commit('SET_LOCATION_LOADING', false)
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    syncLocations: function ({commit, state}, {id, pivots}) {
        commit('app/SET_LOADING', true, {root: true})
        commit('SET_LOCATION_LOADING', true)

        return state.service.updateLocations(id, {pivots: pivots}).then(response => {
            commit('SET_LOCATIONS', response.data)
            commit('SET_LOCATION_PIVOTS', response.pivots)
            commit('app/SET_LOADING', false, {root: true})
            commit('SET_LOCATION_LOADING', false)
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        })
    },
}
