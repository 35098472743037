import service from '@/api/invoice';
import {loading, handleError} from '@/state/generators/helpers';
import {alert} from "../generators/helpers";

export const state = {
    ownLoading: false,
    ownPager: {},
    ownAdditional: {},
    accessibleLoading: false,
    accessiblePager: {},
    accessibleAdditional: {},
    own: [],
    accessible: [],
    service: service('invoices'),
    cancelLoading: false,
    sendLoading: false,
    settleLoading: false,
}

export const getters = {
    ownLoading: function (state) {
        return !!state.ownLoading
    },
    ownPager: function (state) {
        return JSON.parse(JSON.stringify(state.ownPager))
    },
    own: function (state) {
        return state.own
    },
    accessibleLoading: function (state) {
        return !!state.accessibleLoading
    },
    accessiblePager: function (state) {
        return JSON.parse(JSON.stringify(state.accessiblePager))
    },
    accessible: function (state) {
        return state.accessible
    },
    cancelLoading: function (state) {
        return !!state.cancelLoading;
    },
    settleLoading: function (state) {
        return !!state.settleLoading;
    },
    sendLoading: function (state) {
        return !!state.sendLoading;
    },
}

export const mutations = {
    'SET_OWN_LOADING': function (state, boolean = true) {
        state.ownLoading = boolean
    },

    'SET_OWN': function (state, list) {
        state.own = list
    },

    'SET_OWN_PAGER': function (state, pager = {}) {
        state.ownPager = pager
    },

    'SET_OWN_ADDITIONAL': function (state, data = {}) {
        state.ownAdditional = data
    },

    'REMOVE_FROM_OWN': function (state, item) {
        const index = state.own.findIndex(own => own.id === item.id)

        if (index !== -1) {
            state.own.splice(index, 1);
        }
    },

    'SET_ACCESSIBLE_LOADING': function (state, boolean = true) {
        state.accessibleLoading = boolean
    },

    'SET_ACCESSIBLE': function (state, list) {
        state.accessible = list
    },

    'SET_ACCESSIBLE_PAGER': function (state, pager = {}) {
        state.accessiblePager = pager
    },

    'SET_ACCESSIBLE_ADDITIONAL': function (state, data = {}) {
        state.accessibleAdditional = data
    },

    'SET_CANCEL_LOADING': function (state, boolean = true) {
        state.cancelLoading = boolean;
    },

    'SET_SETTLE_LOADING': function (state, boolean = true) {
        state.settleLoading = boolean;
    },

    'SET_SEND_LOADING': function (state, boolean = true) {
        state.sendLoading = boolean;
    },
}

export const actions = {
    own: function ({commit, state}, filters) {
        loading(commit)

        return state.service.fetchOwn(filters).then(response => {
            commit('SET_OWN', response.data)
            commit('SET_OWN_PAGER', response.meta)
            commit('SET_OWN_ADDITIONAL', response.additional)
            loading(commit, false)
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    accessible: function ({commit, state}, filters) {
        loading(commit)

        return state.service.fetchAccessible(filters).then(response => {
            commit('SET_ACCESSIBLE', response.data)
            commit('SET_ACCESSIBLE_PAGER', response.meta)
            commit('SET_ACCESSIBLE_ADDITIONAL', response.additional)
            loading(commit, false)
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    cancel: function ({commit, state}, id) {
        commit('app/SET_LOADING', true, {root: true});
        commit('SET_CANCEL_LOADING', true);

        return state.service.cancel(id).then(response => {
            const model = Object.assign({}, response.data)

            commit('REPLACE_ITEM', model);
            commit('SET_ITEM', model);
            alert(commit, this.$t('request.save_is_successful'));
            commit('SET_ERRORS', {});
            commit('app/SET_LOADING', false, {root: true});
            commit('SET_CANCEL_LOADING', false);

            return model;
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    settle: function ({commit, state}, id) {
        commit('app/SET_LOADING', true, {root: true});
        commit('SET_SETTLE_LOADING', true);

        return state.service.settle(id).then(response => {
            const model = Object.assign({}, response.data)

            commit('REPLACE_ITEM', model);
            commit('SET_ITEM', model);
            alert(commit, this.$t('request.save_is_successful'));
            commit('SET_ERRORS', {});
            commit('app/SET_LOADING', false, {root: true});
            commit('SET_SETTLE_LOADING', false);

            return model;
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    send: function ({commit, state}, id) {
        commit('app/SET_LOADING', true, {root: true});
        commit('SET_SEND_LOADING', true);

        return state.service.send(id).then(response => {
            const model = Object.assign({}, response.data)

            commit('REPLACE_ITEM', model);
            commit('SET_ITEM', model);
            alert(commit, this.$t('request.save_is_successful'));
            commit('SET_ERRORS', {});
            commit('app/SET_LOADING', false, {root: true});
            commit('SET_SEND_LOADING', false);

            return model;
        }).catch((e) => {
            handleError(e, commit)
        })
    },

}
