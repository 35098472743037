import axios from './axios'
import qs from "qs";

function service() {

    function fetchMembers(id, data = {}) {
        return axios.get(`organizations/departments/${id}/members`, { params: data, paramsSerializer: params => qs.stringify(params, { encode: false })  }).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function updateLeader(id, data = {}) {
        return axios.post(`organizations/departments/${id}/leader`, data).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function updateMembers(id, data = {}) {
        return axios.post(`organizations/departments/${id}/members`, data).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function fetchLocations(id, data = {}) {
        return axios.get(`organizations/departments/${id}/locations`, { params: data, paramsSerializer: params => qs.stringify(params, { encode: false })  }).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function updateLocations(id, data = {}) {
        return axios.post(`organizations/departments/${id}/locations`, data).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    return {
        fetchMembers,
        updateMembers,
        updateLeader,
        fetchLocations,
        updateLocations
    }
}

export default service
