import service from '@/api/billableContract';
import {handleError, loading} from "../generators/helpers";

export const state = {
    all: [],
    summary: [],
    service: service('billableContracts')
}

export const mutations = {
    'SET_SUMMARY': function (state, summary) {
        state.summary = summary
    },
}

export const getters = {
    summary: state => {
        return JSON.parse(JSON.stringify(state.summary))
    }
};

export const actions = {
    all: function ({commit, state}, force) {
        if (!state.all || !state.all.length || force) {
            loading(commit)

            let data = typeof force === 'object' ? force : undefined

            if (data) {
                commit('SET_ALL_QUERY', data)
            }

            return state.service.fetchAll(data).then(response => {
                commit('SET_ALL', response.data)
                commit('SET_SUMMARY', response.summary)
                loading(commit, false)
                return response.data
            }).catch((e) => {
                handleError(e, commit)
            });
        }
    },

    summary: function ({commit, state}, id) {
        loading(commit)

        return state.service.fetchSummary({offer_id: id}).then(response => {
            commit('SET_SUMMARY', response.data)
            loading(commit, false)
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        })
    },
}
