import axios from './axios'

function service() {

    function fetchConfiguration() {
        return axios.get('configuration').then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    return {
        fetchConfiguration
    }
}

export default service
