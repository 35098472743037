import {handleError} from './helpers'
import apiServiceGenerator from "@/api/searchGenerator";

export default function (options = {}) {
    const additional = options.additional || {};
    const service = options.endpoint ? {service: apiServiceGenerator(options.endpoint)} : {};
    const additionalGetters = function () {
        const ret = {};
        Object.keys(additional)?.forEach(key => {
            ret[key] = (state) => { return JSON.parse(JSON.stringify(state[key])); }
        })
        return ret;
    }
    const additionalMutations = function () {
        const ret = {};
        Object.keys(additional)?.forEach(key => {
            ret['SET_' + key.toUpperCase()] = (state, list) => {
                state[key] = list;
            }
        })
        return ret;
    }


    return {
        state: {
            loadingSearch: false,
            searchList: [],
            searchFilters: {},
            searchPager: {},
            ...service,
            ...additional
        },
        getters: {
            searchList: (state) => {
                return JSON.parse(JSON.stringify(state.searchList))
            },

            loadingSearch: (state) => {
                return !!state.loadingSearch
            },

            searchEnd: (state) => {
                return state.searchPager?.total === state.searchList?.length
            },
            ...additionalGetters()
        },
        mutations: {
            'SET_SEARCH_LIST': function (state, list = []) {
                state.searchList = list
            },

            'ADD_TO_SEARCH_LIST': function (state, list = []) {
                state.searchList = [...state.searchList, ...list]
            },

            'SET_LOADING_SEARCH': function (state, loading) {
                state.loadingSearch = loading
            },

            'SET_SEARCH_FILTERS': function (state, filters) {
                state.searchFilters = filters;
            },

            'SET_SEARCH_PAGER': function (state, pager) {
                state.searchPager = pager;
            },

            ...additionalMutations()
        },
        actions: {
            search: function ({commit, state}, filters) {
                commit('app/SET_LOADING', true, {root: true})
                commit('SET_LOADING_SEARCH', true)

                const data = Object.assign({page: 1}, filters)

                commit('SET_SEARCH_FILTERS', data)

                const method = state.service.search || state.service.fetchList

                return method(data).then(response => {
                    commit('SET_SEARCH_LIST', response.data)
                    commit('SET_SEARCH_PAGER', response.meta)
                    Object.keys(additional)?.forEach(key => {
                        commit('SET_' + key.toUpperCase(), response[key])
                    })
                    commit('app/SET_LOADING', false, {root: true})
                    commit('SET_LOADING_SEARCH', false)
                    return response.data
                }).catch((e) => {
                    handleError(e, commit)
                    commit('SET_SEARCH_FILTERS', {})
                })
            },

            moreSearch: function ({commit, state}) {
                commit('app/SET_LOADING', true, {root: true})
                commit('SET_LOADING_SEARCH', true)

                const data = Object.assign({}, state.searchFilters, {page: (state.searchPager?.current_page || 1) + 1})

                const method = state.service.search || state.service.fetchList

                return method(data).then(response => {
                    commit('ADD_TO_SEARCH_LIST', response.data)
                    commit('SET_SEARCH_PAGER', response.meta)
                    Object.keys(additional)?.forEach(key => {
                        commit('SET_' + key.toUpperCase(), response[key])
                    })
                    commit('app/SET_LOADING', false, {root: true})
                    commit('SET_LOADING_SEARCH', false)
                    return response.data
                }).catch((e) => {
                    handleError(e, commit)
                })
            },

            clearSearch: function ({commit}) {
                commit('SET_SEARCH_FILTERS', {})
                commit('SET_SEARCH_LIST', [])
                commit('SET_SEARCH_PAGER', {})
            }
        }
    }
}
