import axios from './axios'
// import qs from "qs";

function service() {

    function updateCeo(data = {}) {
        return axios.post(`organizations/positions/ceo`, data).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    return {
        updateCeo
    }
}

export default service
