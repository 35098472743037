import axios from './axios'
import qs from "qs";

function service(endpoint) {

    function fetch(data) {
        return axios.get(`${endpoint}`, { params: data, paramsSerializer: params => qs.stringify(params, { encode: false })  }).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function markAsReadAll() {
        return axios.post(`${endpoint}/mark-as-read-all`).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function markAsRead(id) {
        return axios.post(`${endpoint}/${id}/mark-as-read`).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    return {
        fetch,
        markAsReadAll,
        markAsRead
    }
}

export default service
