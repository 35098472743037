import axios from './axios'
import qs from "qs";

function service(endpoint) {

    function merge(partner_id, ceasing_id, data = {}) {
        return axios.post(`${endpoint}/merge/${partner_id}/${ceasing_id}`, data).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function filterCount(filters = {}) {
        return axios.get(`${endpoint}/count`, { params: filters, paramsSerializer: params => qs.stringify(params, { encode: false })  }).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function fetchOwn(data) {
        return axios.get(`${endpoint}/own`, { params: data, paramsSerializer: params => qs.stringify(params, { encode: false })  }).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function fetchProjects(id, data = {}) {
        return axios.get(`${endpoint}/${id}/projects`, { params: data, paramsSerializer: params => qs.stringify(params, { encode: false })  }).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function fetchAccessible(data) {
        return axios.get(`${endpoint}/accessible`, { params: data, paramsSerializer: params => qs.stringify(params, { encode: false })  }).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    return {
        merge,
        filterCount,
        fetchOwn,
        fetchAccessible,
        fetchProjects
    }
}

export default service
