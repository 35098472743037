import axios from './axios'

function service(endpoint) {

    function complete(id) {
        return axios.post(`${endpoint}/${id}/complete`).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    return {
        complete
    }
}

export default service
