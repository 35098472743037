<template>
    <div id="app">
        <translations></translations>
        <alerts></alerts>
        <router-view v-if="translationsLoaded"></router-view>
        <div v-else class="vw-100 vh-100 d-flex align-items-center justify-content-center">
            <b-spinner></b-spinner>
        </div>
    </div>
</template>

<script>
import appConfig from "@/app.config";
import {mapGetters} from "vuex";
import Alerts from "@/components/alerts.vue";
import Translations from "./components/translations.vue";

export default {
    name: "AACRM",
    components: {Translations, Alerts},

    page: {
        // All subcomponent titles will be injected into this template.
        titleTemplate(title) {
            title = typeof title === "function" ? title(this.$store) : title;
            return title ? `${title} | ${appConfig.title}` : appConfig.title;
        },
    },

    computed: {
        ...mapGetters({
            authenticated: 'auth/authenticated',
            user: 'user/authenticated',
            translations: 'trans/translations',
            aliases: 'trans/aliases',
            locale: 'trans/locale',
            configuration: 'app/configuration',
            translationsLoaded: 'trans/loaded'
        })
    },

    watch: {
        user: {
            immediate: true,
            deep: true,
            handler: function (user) {
                if (this.authenticated && ! user?.id) {
                    this.$store.dispatch('user/authenticated');
                }

                if (!this.configuration && this.authenticated && user?.id) {
                    this.$store.dispatch('app/configuration');
                }
            },
        }
    }
};
</script>
