import service from '@/api/configuration';
import {loading, handleError} from '@/state/generators/helpers';

export const state = {
    loading: false,
    title: '',
    alerts: [],
    configuration: null,
    configurationService: service()
};
export const getters = {
    loading: function (state) {
        return !!state.loading;
    },
    alerts: function (state) {
        return JSON.parse(JSON.stringify(state.alerts));
    },
    configuration: function (state) {
        return state.configuration ? JSON.parse(JSON.stringify(state.configuration)) : null;
    },
    title: function (state) {
        return state.title;
    }
};
export const mutations = {
    'SET_LOADING': function (state, boolean = true) {
        state.loading = boolean;
    },
    'SET_ALERTS': function (state, items) {
        state.alerts = items;
    },
    'SET_CONFIGURATION': function (state, configuration) {
        state.configuration = configuration;
    },
    'ADD_ALERT': function (state, alert) {
        if (!state.alerts) {
            state.alerts = [];
        }
        state.alerts.push(alert);
    },
    'REMOVE_ALERT' (state, index) {
        state.alerts.splice(index, 1);
    },
    'SET_TITLE': function (state, title) {
        state.title = title;
    },
};

export const actions = {
    title: function ({commit}, title) {
        commit('SET_TITLE', title)
    },

    configuration: function ({commit}) {
        loading(commit, true)

        return state.configurationService.fetchConfiguration().then(response => {
            commit('SET_CONFIGURATION', response.data)
            loading(commit, false)
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        })
    },
};
