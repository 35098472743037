import service from '@/api/leadSource';
import {loading, handleError} from '@/state/generators/helpers';

export const state = {
    service: service('leadSources'),
}

export const getters = {
    ownLoading: function (state) {
        return !!state.ownLoading
    },
    ownPager: function (state) {
        return JSON.parse(JSON.stringify(state.ownPager))
    },
    own: function (state) {
        return state.own
    },
    accessibleLoading: function (state) {
        return !!state.accessibleLoading
    },
    accessiblePager: function (state) {
        return JSON.parse(JSON.stringify(state.accessiblePager))
    },
    accessible: function (state) {
        return state.accessible
    },
}

export const mutations = {
    'SET_OWN_LOADING': function (state, boolean = true) {
        state.ownLoading = boolean
    },

    'SET_OWN': function (state, list) {
        state.own = list
    },

    'SET_OWN_PAGER': function (state, pager = {}) {
        state.ownPager = pager
    },

    'SET_OWN_ADDITIONAL': function (state, data = {}) {
        state.ownAdditional = data
    },

    'REMOVE_FROM_OWN': function (state, item) {
        const index = state.own.findIndex(own => own.id === item.id)

        if (index !== -1) {
            state.own.splice(index, 1);
        }
    },

    'SET_ACCESSIBLE_LOADING': function (state, boolean = true) {
        state.accessibleLoading = boolean
    },

    'SET_ACCESSIBLE': function (state, list) {
        state.accessible = list
    },

    'SET_ACCESSIBLE_PAGER': function (state, pager = {}) {
        state.accessiblePager = pager
    },

    'SET_ACCESSIBLE_ADDITIONAL': function (state, data = {}) {
        state.accessibleAdditional = data
    },
}

export const actions = {
    generateToken: function ({commit, state}, filters) {
        loading(commit)

        return state.service.generateToken(filters).then(response => {
            commit('UPDATE_ITEM', response.data)
            loading(commit, false)
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        })
    },

}
