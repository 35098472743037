import service from '@/api/location';

export const state = {
    service: service('organizations/locations'),
}

export const getters = {}

export const mutations = {}

export const actions = {}
