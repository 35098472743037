import service from '@/api/campaign';
import {alert, handleError, loading} from "../generators/helpers";

export const state = {
    service: service('campaigns'),
    filterCount: 0
}

export const mutations = {

}

export const getters = {

};

export const actions = {
    launch: function ({commit, state}, filters) {
        loading(commit)

        return state.service.launch(filters).then(response => {
            commit('ADD_ITEM', response.data)
            commit('SET_ITEM', response.data)
            alert(commit, this.$t('request.creation_is_successful'))
            commit('SET_ERRORS', {});
            loading(commit, false)
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        });
    },
}
