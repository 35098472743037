import crudGenerator from './generators/crud'
import sortableGenerator from './generators/sortable'
import searchableGenerator from './generators/searchable'
import autocompleteGenerator from './generators/autocomplete'

export function crud (list = {}) {
    const modules = {}

    Object.keys(list).forEach(module => {
        modules[module] = crudGenerator(list[module])
    })

    return modules
}

export function sortable (list = {}) {
    const modules = {}

    Object.keys(list).forEach(module => {
        modules[module] = sortableGenerator(list[module])
    })

    return modules
}

export function searchable (list = {}) {
    const modules = {}

    Object.keys(list).forEach(module => {
        modules[module] = searchableGenerator(list[module])
    })

    return modules
}

export function autocomplete (list = {}) {
    const modules = {}

    Object.keys(list).forEach(module => {
        modules[module] = autocompleteGenerator(list[module])
    })

    return modules
}
