import {alert} from "../generators/helpers";
import {handleError} from "vue";
import service from '@/api/implementPart';

export const state = {
    created: null,
    edited: null,
    parent: null,
    createdBillable: null,
    editedBillable: null,
    service: service('implementParts')
}
export const getters = {
    created: function (state) {
        return JSON.parse(JSON.stringify(state.created));
    },
    edited: function (state) {
        return JSON.parse(JSON.stringify(state.edited));
    },
    billables: state => (type) => {
        return JSON.parse(JSON.stringify(state[type]?.billables || []));
    },
    createdBillable: function (state) {
        return JSON.parse(JSON.stringify(state.createdBillable));
    },
    editedBillable: function (state) {
        return JSON.parse(JSON.stringify(state.editedBillable));
    },
}
export const mutations = {
    'SET_CREATED': function (state, item = {}) {
        state.created = Object.assign({
            implement_id: null,
            implement_part_id: null,
            documenter_type: 'App\\Models\\User',
            documenter_id: null,
            documented_at: null,
            document_id: null,
            document_link: null,
            name: null,
            start: null,
            end: null,
            deadline: null,
            location: null,
            documenter: null,
            completed_at: null,
            implementers: [],
            billables: [],
            implement_parts: [],
            notification: '',
        }, item);
    },

    'SET_EDITED': function (state, item) {
        state.edited = item;
    },

    'SET_PARENT': function (state, item) {
        state.parent = item;
    },

    'PUSH': function (state, item) {
        if (! Array.isArray(state.parent.implement_parts)) {
            state.parent.implement_parts = [item];
        } else {
            state.parent.implement_parts.push(item);
        }
        state.created = null;
    },

    'UPDATE_EDITED': function (state, item) {
        Object.assign(state.edited, item);
    },

    'UPDATE_CREATED': function (state, item) {
        Object.assign(state.created, item);
    },

    'CLEAR': function (state) {
        state.created = null;
        state.edited = null;
        state.parent = null;
    },

    'SET_CREATED_BILLABLE': function (state, item = {}) {
        state.createdBillable = Object.assign({}, item);
    },

    'SET_EDITED_BILLABLE': function (state, item) {
        state.editedBillable = item;
    },

    'UPDATE_EDITED_BILLABLE': function (state, item) {
        Object.assign(state.editedBillable, item);
    },

    'UPDATE_CREATED_BILLABLE': function (state, item) {
        Object.assign(state.createdBillable, item);
    },

    'PUSH_BILLABLE': function (state, item) {
        if (! Array.isArray(state.edited.billables)) {
            state.edited.billables = [item];
        } else {
            state.edited.billables.push(item);
        }
        console.log(state)
        state.createdBillable = null;
    },
}
export const actions = {
    edit: function ({commit}, item) {
        commit('SET_EDITED', item);
    },

    create: function ({commit}, item) {
        commit('SET_CREATED', item);
    },

    parent: function ({commit}, item) {
        commit('SET_PARENT', item);
    },

    push: function ({commit, getters}) {
        commit('PUSH', getters.created);
    },

    editedUpdate: function ({commit, getters}) {
        commit('UPDATE_EDITED', getters.edited);
    },

    createdUpdate: function ({commit, getters}) {
        commit('UPDATE_CREATED', getters.created);
    },

    clear: function ({commit}) {
        commit('CLEAR');
    },

    createBillable: function ({commit}, billable) {
        commit('SET_CREATED_BILLABLE', billable);
    },

    editBillable: function ({commit}, billable) {
        commit('SET_EDITED_BILLABLE', billable);
    },

    editedBillableUpdate: function ({commit, getters}, item) {
        commit('UPDATE_EDITED_BILLABLE', Object.assign({}, getters.editedBillable, item));
    },

    createdBillableUpdate: function ({commit, getters}, item) {
        commit('UPDATE_CREATED_BILLABLE', Object.assign({}, getters.createdBillable, item));
    },

    pushBillable: function ({commit, getters}, item) {
        commit('PUSH_BILLABLE', Object.assign({}, getters.createdBillable, item));
    },

    complete: function ({commit}, id) {
        commit('app/SET_LOADING', true, {root: true});
        commit('SET_LOADING', true);

        return state.service.complete(id).then(response => {
            const model = Object.assign({}, response.data)

            commit('REPLACE_ITEM', model);
            commit('SET_ITEM', model);
            alert(commit, this.$t('request.save_is_successful'));
            commit('SET_ERRORS', {});
            commit('app/SET_LOADING', false, {root: true});
            commit('SET_LOADING', false);

            return model;
        }).catch((e) => {
            handleError(e, commit)
        })
    },
}