import service from '@/api/projectStep';
import {handleError} from '@/state/generators/helpers';

export const state = {
    service: service('projectSteps'),
    requirements: [],
    fields: {},
    interactions: [],
    relations: {},
    status: null,
    requirementsLoading: false,
    tasksLoading: false,
    taskLoading: {},
    tasks: [],
    deadlinesLoading: false,
    deadlines: []
}

export const getters = {
    requirementsLoading: function (state) {
        return !!state.requirementsLoading;
    },
    requirements: function (state) {
        return JSON.parse(JSON.stringify(state.requirements));
    },
    fields: function (state) {
        return JSON.parse(JSON.stringify(state.fields));
    },
    requiredFields: (state) => (prop) => {
        return JSON.parse(JSON.stringify(state.fields[prop] ?? []));
    },
    relations: function (state) {
        return JSON.parse(JSON.stringify(state.relations));
    },
    interactions: function (state) {
        return state.interactions;
    },
    status: function (state) {
        return state.status;
    },
    tasksLoading: function (state) {
        return !!state.tasksLoading;
    },
    tasks: function (state) {
        return JSON.parse(JSON.stringify(state.tasks));
    },
    deadlinesLoading: function (state) {
        return !!state.deadlinesLoading;
    },
    deadlines: function (state) {
        return JSON.parse(JSON.stringify(state.deadlines));
    },
    taskLoading: (state) => (id) => {
        return !! state.taskLoading[id];
    }
}

export const mutations = {
    'SET_RELATIONS': function (state, list = {}) {
        state.relations = list;
    },

    'SET_INTERACTIONS': function (state, list = []) {
        state.interactions = list;
    },

    'SET_STATUS': function (state, id = null) {
        state.status = id;
    },

    'SET_FIELDS': function (state, list = {}) {
        state.fields = list;
    },

    'SET_REQUIREMENTS': function (state, list = []) {
        state.requirements = list;
    },

    'SET_REQUIREMENTS_LOADING': function (state, bool = true) {
        state.requirementsLoading = bool;
    },

    'SET_TASKS': function (state, list = []) {
        state.tasks = list;
    },

    'SET_TASKS_LOADING': function (state, bool = true) {
        state.tasksLoading = bool;
    },

    'SET_DEADLINES': function (state, list = []) {
        state.deadlines = list;
    },

    'SET_DEADLINES_LOADING': function (state, bool = true) {
        state.deadlinesLoading = bool;
    },

    'REMOVE_TASK': function (state, id) {
        const index = state.tasks.findIndex(item => item.id === id);

        if (index !== -1) {
            state.tasks.splice(index, 1)
            state.tasks = JSON.parse(JSON.stringify(state.tasks));
        }
    },

    'CHANGE_TASK': function (state, {id, user}) {
        const index = state.tasks.findIndex(item => item.id === id);

        if (index !== -1) {
            state.tasks[index].assigned = user;
            state.tasks = JSON.parse(JSON.stringify(state.tasks));
        }
    },

    'SET_TASK_LOADING': function (state, {id, loading}) {
        state.taskLoading[id] = loading;
    },
}

export const actions = {
    requirements: function ({commit, state}, id) {
        commit('app/SET_LOADING', true, {root: true});
        commit('SET_REQUIREMENTS_LOADING', true);

        return state.service.fetchRequirements(id).then(response => {
            commit('SET_FIELDS', response.data?.required_fields);
            commit('SET_RELATIONS', response.data?.required_relations);
            commit('SET_REQUIREMENTS', response.data?.messages);
            commit('SET_STATUS', response.data?.required_status);
            commit('SET_INTERACTIONS', response.data?.required_interactions);
            commit('app/SET_LOADING', false, {root: true});
            commit('SET_REQUIREMENTS_LOADING', false);
            return response.data;
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    tasks: function ({commit, state}, id) {
        commit('app/SET_LOADING', true, {root: true});
        commit('SET_TASKS_LOADING', true);

        return state.service.fetchTasks(id).then(response => {
            commit('SET_TASKS', response.data);
            commit('app/SET_LOADING', false, {root: true});
            commit('SET_TASKS_LOADING', false);
            return response.data;
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    deadlines: function ({commit, state}, id) {
        commit('app/SET_LOADING', true, {root: true});
        commit('SET_DEADLINES_LOADING', true);

        return state.service.fetchDeadlines(id).then(response => {
            commit('SET_DEADLINES', response.data);
            commit('app/SET_LOADING', false, {root: true});
            commit('SET_DEADLINES_LOADING', false);
            return response.data;
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    clearRequirements: function ({commit}) {
        commit('SET_INTERACTIONS', []);
        commit('SET_STATUS', null);
        commit('SET_REQUIREMENTS', []);
        commit('SET_FIELDS', {});
    },

    clearTasks: function ({commit}) {
        commit('SET_TASKS', []);
    },

    clearDeadlines: function ({commit}) {
        commit('SET_DEADLINES', []);
    },

    removeTask: function ({commit}, id) {
        commit('REMOVE_TASK', id);
    },

    changeTaskAssigned: function ({commit}, payload) {
        commit('CHANGE_TASK', payload);
    },

    setTaskLoading: function ({commit}, payload = true) {
        commit('SET_TASK_LOADING', payload);
    },
}
