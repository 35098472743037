
export const state = {
    all: []
}

export const mutations = {

}

export const getters = {
    products: function (state) {
        return JSON.parse(JSON.stringify(state.all.filter(item => item.type === 'Appon\\Projects\\Models\\Product')));
    },
    services: function (state) {
        return JSON.parse(JSON.stringify(state.all.filter(item => item.type === 'Appon\\Projects\\Models\\Service')));
    },
};

export const actions = {

}
