import axios from './axios'
import qs from "qs";

function service(endpoint) {

    function me(filters) {
        return axios.get(`${endpoint}/me`, { params: filters, paramsSerializer: params => qs.stringify(params, { encode: false })  }).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function personalPerformanceWithTargetValues(id) {
        return axios.get(`performances/${id}/personalPerformanceWithTargetValues`).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function personalPerformance(id) {
        return axios.get(`performances/${id}/personalPerformance`).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function subordinates(id) {
        return axios.get(`performances/${id}/subordinates`).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function weekly(id) {
        return axios.get(`performances/${id}/weekly`).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function monthly(id) {
        return axios.get(`performances/${id}/monthly`).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function yearly(id) {
        return axios.get(`performances/${id}/yearly`).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    return {
        me,
        personalPerformanceWithTargetValues,
        personalPerformance,
        subordinates,
        weekly,
        monthly,
        yearly
    }
}

export default service
