import axios from './axios'
import qs from "qs";

function service() {

    function fetchUserIntroduction(id, data = {}) {
        return axios.get(`organizations/users/${id}/introduction`, { params: data, paramsSerializer: params => qs.stringify(params, { encode: false })  }).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    return {
        fetchUserIntroduction
    }
}

export default service
