import service from '@/api/user';
import {loading, alert, handleError, destroyLocal, storeLocal} from '@/state/generators/helpers';

export const state = {
    loading: false,
    loadingSearch: false,
    searchList: [],
    searchFilters: {},
    searchPager: {},
    loadingPositions: false,
    positions: [],
    positionPivots: [],
    loadingSkills: false,
    skills: [],
    skillPivots: [],
    authenticated: sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')) : {},
    service: service('users'),
    implementers: [],
    potentialSubordinates: [],
    profile: {},
    checkedSubordinates: {},
    loadingCheckSubordinate: false,
    loadingTasks: false,
    tasks: [],
    calendarLoading: false,
    calendar: [],
}

export const getters = {
    authenticated: (state) => {
        return JSON.parse(JSON.stringify(state.authenticated))
    },

    searchList: (state) => {
        return JSON.parse(JSON.stringify(state.searchList))
    },

    loadingSearch: (state) => {
        return !!state.loadingSearch
    },

    loadingPositions: (state) => {
        return !!state.loadingPositions
    },

    positions: (state) => {
        return JSON.parse(JSON.stringify(state.positions))
    },

    positionPivots: (state) => {
        return JSON.parse(JSON.stringify(state.positionPivots))
    },

    loadingSkills: (state) => {
        return !!state.loadingSkills
    },

    skills: (state) => {
        return JSON.parse(JSON.stringify(state.skills))
    },

    skillPivots: (state) => {
        return JSON.parse(JSON.stringify(state.skillPivots))
    },

    searchEnd: (state) => {
        return state.searchPager?.total === state.searchList?.length
    },

    profile: (state) => {
        return JSON.parse(JSON.stringify(state.profile))
    },

    firstLetter: (state) => {
        return state.authenticated.id ? state.authenticated.firstname[0] : ''
    },

    implementers: (state) => {
        return state.implementers
    },

    potentialSubordinates: (state) => {
        return state.potentialSubordinates
    },

    loadingCheckSubordinate: (state) => {
        return state.loadingCheckSubordinate
    },

    loadingTasks: (state) => {
        return !!state.loadingTasks
    },

    tasks: (state) => {
        return JSON.parse(JSON.stringify(state.tasks))
    },

    calendarLoading: (state) => {
        return !!state.calendarLoading
    },

    calendar: (state) => {
        return JSON.parse(JSON.stringify(state.calendar))
    },
}

export const mutations = {
    'SET_LOADING': function (state, boolean = true) {
        state.loading = boolean
    },

    'SET_AUTHENTICATED': function (state, user) {
        state.authenticated = user;
        user ? storeLocal('user', user) : destroyLocal('user');
    },

    'SET_PROFILE': function (state, user) {
        state.profile = user
    },

    'SET_IMPLEMENTERS': function (state, list = []) {
        state.implementers = list
    },

    'SET_POTENTIAL_SUBORDINATES': function (state, list = []) {
        state.potentialSubordinates = list
    },

    'SET_LOADING_CHECK_SUBORDINATE': function (state, loading) {
        state.loadingCheckSubordinate = loading
    },

    'SET_SEARCH_LIST': function (state, list = []) {
        state.searchList = list
    },

    'ADD_TO_SEARCH_LIST': function (state, list = []) {
        state.searchList = [...state.searchList, ...list]
    },

    'SET_LOADING_SEARCH': function (state, loading) {
        state.loadingSearch = loading
    },

    'SET_CHECK_SUBORDINATE': function (state, check) {
        state.checkedSubordinates = Object.assign(state.checkedSubordinates, check);
    },

    'SET_SEARCH_FILTERS': function (state, filters) {
        state.searchFilters = filters;
    },

    'SET_SEARCH_PAGER': function (state, pager) {
        state.searchPager = pager;
    },

    'SET_POSITIONS': function (state, list = []) {
        state.positions = list;
    },

    'SET_POSITION_PIVOTS': function (state, list = []) {
        state.positionPivots = list;
    },

    'SET_LOADING_POSITIONS': function (state, loading) {
        state.loadingPositions = loading
    },

    'SET_SKILLS': function (state, list = []) {
        state.skills = list;
    },

    'SET_SKILL_PIVOTS': function (state, list = []) {
        state.skillPivots = list;
    },

    'SET_LOADING_SKILLS': function (state, loading) {
        state.loadingSkills = loading
    },

    'SET_TASKS': function (state, list = []) {
        state.tasks = list;
    },

    'SET_LOADING_TASKS': function (state, loading) {
        state.loadingTasks = loading
    },

    'SET_CALENDAR': function (state, list = []) {
        state.calendar = list;
    },

    'SET_LOADING_CALENDAR': function (state, loading) {
        state.calendarLoading = loading
    },

    'UPDATE_TASK_STATUS': function (state, {id, status}) {
        const index = state.tasks.findIndex(listItem => listItem.id === id);

        if (index !== -1) {
            state.tasks[index].status = status
            state.tasks = JSON.parse(JSON.stringify(state.tasks))
        }
    },

    'REMOVE_TASK_FROM_LIST': function (state, id) {
        const index = state.tasks.findIndex(listItem => listItem.id === id);

        if (index !== -1) {
            state.tasks.splice(index, 1);
            state.tasks = JSON.parse(JSON.stringify(state.tasks));
        }
    },

    'UPDATE_TASK_ASSIGNED': function (state, {id, user}) {
        const index = state.tasks.findIndex(listItem => listItem.id === id);

        if (index !== -1) {
            state.tasks[index].assigned_id = user.id;
            state.tasks[index].assigned_type = 'App\\Models\\User';
            state.tasks[index].assigned = user;
            state.tasks = JSON.parse(JSON.stringify(state.tasks))
        }
    },
}

export const actions = {
    authenticated: function ({commit, state, dispatch}) {
        loading(commit)

        return state.service.fetchAuthenticated().then(data => {
            commit('SET_AUTHENTICATED', data.data)
            dispatch('notification/subscribe', {}, {root: true})
            dispatch('notification/fetch', {}, {root: true})
            loading(commit, false)
            return data
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    profile: function ({commit, state}, id) {
        loading(commit)

        return state.service.fetchProfile(id).then(data => {
            commit('SET_PROFILE', data.data)
            loading(commit, false)
            return data
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    setAuthenticated: function ({commit}, user) {
        commit('SET_AUTHENTICATED', user)
    },

    clearProfile: function ({commit}) {
        commit('SET_PROFILE', {})
    },

    changePassword: function ({commit, state}, passwords) {
        loading(commit)

        const id = passwords.id

        delete passwords.id

        return state.service.changePassword(id, passwords).then(data => {
            loading(commit, false)
            commit('SET_ERRORS', {})
            alert(commit, this.$t('users.password_changed').ucFirst())
            return data
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    roles: function ({commit, state}, payload) {
        loading(commit)

        const id = payload.id
        delete payload.id

        return state.service.syncRoles(id, payload).then(response => {
            loading(commit, false)
            alert(commit, this.$t('request.save_is_successful'))
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    roleGroups: function ({commit, state}, payload) {
        loading(commit)

        const id = payload.id
        delete payload.id

        return state.service.syncRoleGroups(id, payload).then(response => {
            loading(commit, false)
            alert(commit, this.$t('request.save_is_successful'))
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    implementers: function ({commit, state}, filters) {
        loading(commit)

        return state.service.fetchImplementers(filters).then(response => {
            commit('SET_IMPLEMENTERS', response.data)
            loading(commit, false)
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    potentialSuperiors: function ({commit, state}, payload = null) {
        loading(commit)

        return state.service.fetchPotentialSuperiors(payload?.id || state.authenticated?.id).then(response => {
            loading(commit, false)
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    potentialSubordinates: function ({commit, state}, payload = null) {
        loading(commit)

        return state.service.fetchPotentialSubordinates(payload?.id || state.authenticated?.id).then(response => {
            commit('SET_POTENTIAL_SUBORDINATES', response.data)
            loading(commit, false)
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    subordinates: function ({commit, state}, payload = null) {
        loading(commit)

        return state.service.fetchSubordinates(payload?.id || state.authenticated?.id).then(response => {
            loading(commit, false)
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    superior: function ({commit, state}, payload = null) {
        loading(commit)

        return state.service.fetchSuperior(payload?.id || state.authenticated?.id).then(response => {
            loading(commit, false)
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    syncSubordinates: function ({commit, state}, {id, data}) {
        loading(commit)

        return state.service.syncSubordinates(id || state.authenticated?.id, data).then(response => {
            loading(commit, false)
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    checkSubordinate: function ({commit, state}, id) {
        commit('app/SET_LOADING', true, {root: true})
        commit('SET_LOADING_CHECK_SUBORDINATE', true)

        if (Object.keys(state.checkedSubordinates).includes(id)) {
            return state.checkedSubordinates[id]
        }

        return state.service.checkSubordinate(id).then(response => {
            commit('app/SET_LOADING', false, {root: true})
            commit('SET_LOADING_CHECK_SUBORDINATE', false)
            commit('SET_CHECK_SUBORDINATE', response.data)
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    search: function ({commit, state}, filters) {
        commit('app/SET_LOADING', true, {root: true})
        commit('SET_LOADING_SEARCH', true)

        const data = Object.assign({page: 1}, filters)

        commit('SET_SEARCH_FILTERS', data)

        return state.service.search(data).then(response => {
            commit('SET_SEARCH_LIST', response.data)
            commit('SET_SEARCH_PAGER', response.meta)
            commit('app/SET_LOADING', false, {root: true})
            commit('SET_LOADING_SEARCH', false)
            return response.data
        }).catch((e) => {
            handleError(e, commit)
            commit('SET_SEARCH_FILTERS', {})
        })
    },

    moreSearch: function ({commit, state}) {
        commit('app/SET_LOADING', true, {root: true})
        commit('SET_LOADING_SEARCH', true)

        const data = Object.assign({}, state.searchFilters, {page: (state.searchPager?.current_page || 1) + 1})

        return state.service.search(data).then(response => {
            commit('ADD_TO_SEARCH_LIST', response.data)
            commit('SET_SEARCH_PAGER', response.meta)
            commit('app/SET_LOADING', false, {root: true})
            commit('SET_LOADING_SEARCH', false)
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    clearSearch: function ({commit}) {
        commit('SET_SEARCH_FILTERS', {})
        commit('SET_SEARCH_LIST', [])
        commit('SET_SEARCH_PAGER', {})
    },

    positions: function ({commit, state}, id) {
        commit('app/SET_LOADING', true, {root: true})
        commit('SET_LOADING_POSITIONS', true)


        return state.service.fetchPositions(id).then(response => {
            commit('SET_POSITIONS', response.data)
            commit('SET_POSITION_PIVOTS', response.pivots)
            commit('app/SET_LOADING', false, {root: true})
            commit('SET_LOADING_POSITIONS', false)
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    syncPositions: function ({commit, state}, {id, pivots}) {
        commit('app/SET_LOADING', true, {root: true})
        commit('SET_LOADING_POSITIONS', true)

        return state.service.updatePositions(id, {pivots: pivots}).then(response => {
            commit('SET_POSITIONS', response.data)
            commit('SET_POSITION_PIVOTS', response.pivots)
            commit('app/SET_LOADING', false, {root: true})
            commit('SET_LOADING_POSITIONS', false)
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    skills: function ({commit, state}, id) {
        commit('app/SET_LOADING', true, {root: true})
        commit('SET_LOADING_SKILLS', true)


        return state.service.fetchSkills(id).then(response => {
            commit('SET_SKILLS', response.data)
            commit('SET_SKILL_PIVOTS', response.pivots)
            commit('app/SET_LOADING', false, {root: true})
            commit('SET_LOADING_SKILLS', false)
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    syncSkills: function ({commit, state}, {id, pivots}) {
        commit('app/SET_LOADING', true, {root: true})
        commit('SET_LOADING_SKILLS', true)

        return state.service.updateSkills(id, {pivots: pivots}).then(response => {
            commit('SET_SKILLS', response.data)
            commit('SET_SKILL_PIVOTS', response.pivots)
            commit('app/SET_LOADING', false, {root: true})
            commit('SET_LOADING_SKILLS', false)
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    tasks: function ({commit, state}, id) {
        commit('app/SET_LOADING', true, {root: true})
        commit('SET_LOADING_TASKS', true)

        return state.service.fetchTasks(id, {with: ['interaction', 'projectStep'], sorting: { column: 'deadline', desc: false }} ).then(response => {
            commit('SET_TASKS', response.data)
            commit('app/SET_LOADING', false, {root: true})
            commit('SET_LOADING_TASKS', false)
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    closedTasks: function ({commit, state}, id) {
        commit('app/SET_LOADING', true, {root: true})
        commit('SET_LOADING_TASKS', true)

        return state.service.fetchClosedTasks(id, { sorting: { column: 'closed_at', desc: true } }).then(response => {
            commit('SET_TASKS', response.data)
            commit('app/SET_LOADING', false, {root: true})
            commit('SET_LOADING_TASKS', false)
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    removeTaskFromList: function ({commit}, id) {
        commit('REMOVE_TASK_FROM_LIST', id);
    },

    updateTaskStatus: function ({commit}, {id, status}) {
        commit('UPDATE_TASK_STATUS', {id: id, status: status});
    },

    updateTaskAssigned: function ({commit}, payload) {
        commit('UPDATE_TASK_ASSIGNED', payload);
    },

    calendar: function ({commit}, {id, filters}) {
        commit('app/SET_LOADING', true, {root: true})
        commit('SET_LOADING_CALENDAR', true)

        return state.service.fetchCalendar(id, filters).then(response => {
            commit('SET_CALENDAR', response.data)
            commit('app/SET_LOADING', false, {root: true})
            commit('SET_LOADING_CALENDAR', false)
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        })
    },
}
