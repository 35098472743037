import axios from './axios'
import qs from "qs";

function service(endpoint) {

    function relations(model, data) {
        return axios.get(`${endpoint}/${model}`, { params: data, paramsSerializer: params => qs.stringify(params, { encode: false })  }).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    return {
        relations
    }
}

export default service
