import router from "../../router";

export const changes = function (payload, item) {
    const id = payload.id;

    const fileProps = Object.keys(payload).filter(prop => {
        return prop.endsWith('_file')
    })

    const fileLists = Object.keys(payload).filter(prop => {
        return payload[prop] instanceof FileList
    })

    const files = {}

    for (let prop of fileProps) {
        files[prop] = payload[prop]
        delete payload[prop]
    }

    fileLists.forEach(list => {
        if (payload[list].length > 1) {
            files[list] = [...payload[list]];
        }
        if (payload[list].length === 1) {
            files[list] = payload[list][0]
        }
    })

    let data = JSON.parse(JSON.stringify(payload));

    delete data.id;

    for (let prop in payload) {
        if (
            item[prop] === payload[prop]
            || (
                Array.isArray(payload[prop])
                && Array.isArray(item[prop])
                && JSON.stringify(item[prop]) === JSON.stringify(payload[prop])
            )
            || (
                typeof item[prop] === 'object' && typeof payload[prop] === 'object'
                && ((!item[prop] && !payload[prop]) || JSON.stringify(item[prop]) === JSON.stringify(payload[prop]))
            )
        ) {
            delete data[prop];
        }
    }

    let base = {}

    if (payload?.query) {
        base = JSON.parse(JSON.stringify(payload.query));
    }

    data = Object.assign(base || {}, data, files);

    return {
        id: id,
        data: data
    }
}
export const handleError = function (e, commit) {
    if (e && e.response && e.response.data && e.response.data.errors) {
        commit('SET_ERRORS', e.response.data.errors);
    }

    let message = ''

    if (e && e.response && e.response.data && e.response.data.message) {
        message = e.response.data.message
    } else {
        // const key = 'request.' + (e && e.response && e.response.status ? e.response.status : 'error')

        // message = this.$t(key).ucFirst()
    }

    commit('app/ADD_ALERT', {
        message: message,
        style: 'danger',
        icon: 'error',
        countdown: 3000
    }, { root: true })
    loading(commit, false)
    switch (e.response.status) {
        case 401:
            router.push({name: 'login'});
            break;
        case 302:
            router.push(e.response.data.redirect);
            break;
        // case 403:
            // Todo create 403 page
        default:
            throw e
    }
}

export const loading = function (commit, loading = true) {
    commit('app/SET_LOADING', loading, {root: true})
    commit('SET_LOADING', loading)
}

export const alert = function (commit, message, style = 'success', icon = 'success', countdown = 3000) {
    commit('app/ADD_ALERT', {
        message: message,
        style: style,
        icon: icon,
        countdown: countdown
    }, { root: true });
}

export function destroyLocal(key) {
    window.localStorage.removeItem(key);
    window.sessionStorage.removeItem(key);
}

export function storeLocal(key, value) {
    window.localStorage.setItem(key, JSON.stringify(value));
    window.sessionStorage.setItem(key, JSON.stringify(value));
}

export function getLocal(key) {
    const item = window.sessionStorage.getItem(key) || window.localStorage.getItem(key)

    if (item !== null) {
        return JSON.parse(item)
    }

    return null
}
