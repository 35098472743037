export const state = {
    list: null,
    created: null,
    edited: null,
}
export const getters = {
    list: function (state) {
        return JSON.parse(JSON.stringify(state.list));
    },
    created: function (state) {
        return JSON.parse(JSON.stringify(state.created));
    },
    edited: function (state) {
        return JSON.parse(JSON.stringify(state.edited));
    },
    summary: function (state) {
        return state.list?.reduce((total, item) => {
            return total + Math.ceil(item?.quantity * (parseFloat(item?.price) + parseFloat(item?.vat)));
        }, 0);
    },
}
export const mutations = {
    'SET_LIST': function (state, items = []) {
        state.list = items || [];
    },

    'SET_CREATED': function (state, item = {}) {
        state.created = Object.assign({}, item);
    },

    'SET_EDITED': function (state, index) {
        state.edited = state.list[index];
    },

    'REMOVE': function (state, index) {
        state.list.splice(index, 1);
    },

    'PUSH': function (state, item) {
        state.list.push(item);
        state.created = null;
    },

    'UPDATE_EDITED': function (state, item) {
        Object.assign(state.edited, item);
    },

    'UPDATE': function (state, item) {
        Object.assign(state.edited, item);
        state.edited = null;
    },

    'UPDATE_CREATED': function (state, item) {
        Object.assign(state.created, item);
    },

    'CLEAR': function (state) {
        state.created = null;
        state.edited = null;
    },

    'CLEAR_LIST': function (state) {
        state.list = null;
    }
}
export const actions = {
    init: function ({commit}, list) {
        commit('SET_LIST', list || []);
    },

    create: function ({commit}, item) {
        commit('SET_CREATED', item || {});
    },

    remove: function ({commit}, index) {
        commit('REMOVE', index);
    },

    edit: function ({commit}, index) {
        commit('SET_EDITED', index);
    },

    add: function ({commit, getters}) {
        commit('PUSH', getters.created);
    },

    update: function ({commit, getters}) {
        commit('UPDATE', getters.edited);
    },

    editedUpdate: function ({commit}, item) {
        commit('UPDATE_EDITED', item);
    },

    createdUpdate: function ({commit}, item) {
        commit('UPDATE_CREATED', item);
    },

    clear: function ({commit}) {
        commit('CLEAR');
    },

    clearAll: function ({commit}) {
        commit('CLEAR');
        commit('CLEAR_LIST');
    }
}