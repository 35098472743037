export default [
    {
        path: '/',
        name: 'home',
        meta: {
            authentication_required: true,
            authorization_required: null
        },
        component: () => import('../views/layouts/main.vue'),
        children: [
            {
                path: '/subordinates',
                name: 'subordinates',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/users/subordinates-dashboard.vue')
            },
            {
                path: '/partners/my-partners',
                name: 'my-partners',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/partners/my.vue')
            },
            {
                path: '/partners/all',
                name: 'all-partner',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/partners/all.vue')
            },
            {
                path: '/partners/free',
                name: 'free-partner',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/partners/free.vue')
            },
            {
                path: '/partners/merge',
                name: 'merge-partner',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/partners/merge.vue')
            },
            {
                path: '/partners/campaign',
                name: 'launch-campaign',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/partners/campaign.vue')
            },
            {
                path: '/partners/my-partners/create/:type?',
                name: 'my-partner-create',
                component: () => import('../views/pages/partners/item.vue')
            },
            {
                path: '/partners/create/:type?',
                name: 'partner-create',
                component: () => import('../views/pages/partners/item.vue')
            },
            {
                path: '/partners/:id(\\d+)/:type?',
                name: 'partner-edit',
                component: () => import('../views/pages/partners/item.vue'),
                redirect: { name: 'partner-base-information'},
                children: [
                    {
                        path: '/partners/:id(\\d+)/projects/:type?',
                        name: 'partner-projects',
                        component: () => import('../views/pages/projects/partner.vue')
                    },
                    {
                        path: '/partners/:id(\\d+)/base-information/:type?',
                        name: 'partner-base-information',
                        component: () => import('../views/pages/partners/edit.vue')
                    },
                    {
                        path: '/partners/:id(\\d+)/contacts/:type?',
                        name: 'partner-contacts',
                        component: () => import('../views/pages/contacts/manage.vue')
                    },
                    {
                        path: '/partners/:id(\\d+)/decision-makers/:type?',
                        name: 'partner-decision-makers',
                        component: () => import('../views/pages/decision-makers/manage.vue')
                    },
                    {
                        path: '/partners/:id(\\d+)/addresses/:type?',
                        name: 'partner-addresses',
                        component: () => import('../views/pages/addresses/manage.vue')
                    },
                ]
            },
            {
                path: '/interactions/my-interactions',
                name: 'my-interactions',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/interactions/own.vue')
            },
            {
                path: '/interactions/my-meetings',
                name: 'my-meetings',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/meetings/own.vue')
            },
            {
                path: '/interactions/my-meetings/create',
                name: 'my-meeting-create',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/meetings/item.vue'),
                props: { own: true }
            },
            {
                path: '/interactions/my-meetings/:id(\\d+)',
                name: 'my-meeting-edit',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/meetings/item.vue'),
                props: { own: true },
            },
            {
                path: '/interactions/my-offers',
                name: 'my-offers',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/offers/own.vue')
            },
            {
                path: '/interactions/my-offers/create',
                name: 'my-offer-create',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/offers/item.vue'),
                props: { own: true }
            },
            {
                path: '/interactions/my-offers/:id(\\d+)',
                name: 'my-offer-edit',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/offers/item.vue'),
                props: { own: true },
            },
            {
                path: '/interactions/my-contracts',
                name: 'my-contracts',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/contracts/own.vue')
            },
            {
                path: '/interactions/my-contracts/create',
                name: 'my-contract-create',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/contracts/item.vue'),
                props: { own: true }
            },
            {
                path: '/interactions/my-contracts/:id(\\d+)',
                name: 'my-contract-edit',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/contracts/item.vue'),
                props: { own: true },
            },
            {
                path: '/interactions/my-implements',
                name: 'my-implements',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/implements/own.vue')
            },
            {
                path: '/interactions/my-implements/create',
                name: 'my-implement-create',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/implements/item.vue'),
                props: { own: true }
            },
            {
                path: '/interactions/my-implements/:id(\\d+)',
                name: 'my-implement-edit',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/implements/item.vue'),
                props: { own: true },
            },
            {
                path: '/interactions/my-invoices',
                name: 'interactions-my-invoices',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/invoices/own.vue')
            },
            /*{
                path: '/interactions/my-invoices/create',
                name: 'my-invoice-create',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/invoices/item.vue'),
                props: { own: true }
            },
            {
                path: '/interactions/my-invoices/:id(\\d+)',
                name: 'my-invoice-edit',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/invoices/item.vue'),
                props: { own: true },
            },*/
            {
                path: '/projects/my-projects',
                name: 'my-projects',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/projects/own.vue'),
                props: { own: true },
            },
            {
                path: '/projects/my-projects/create',
                name: 'my-project-create',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/projects/item.vue'),
                props: { own: true }
            },
            {
                path: '/projects/my-projects/:id(\\d+)',
                name: 'my-project-edit',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/projects/item.vue'),
                props: { own: true },
            },
            {
                path: '/projects/accessible-projects',
                name: 'accessible-projects',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/projects/accessible.vue'),
            },
            {
                path: '/projects/accessible-projects/:id(\\d+)',
                name: 'accessible-project-edit',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/projects/item.vue'),
            },
            {
                path: '/invoices/my-invoices',
                name: 'my-invoices',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/invoices/own.vue'),
                props: { own: true },
            },
            {
                path: '/invoices/my-invoices/create',
                name: 'my-invoice-create',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/invoices/item.vue'),
                props: { own: true }
            },
            {
                path: '/invoices/my-invoices/:id(\\d+)',
                name: 'my-invoice-edit',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/invoices/item.vue'),
                props: { own: true },
            },
            {
                path: '/invoices/accessible-invoices',
                name: 'accessible-invoices',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/invoices/accessible.vue'),
            },
            {
                path: '/invoices/accessible-invoices/:id(\\d+)',
                name: 'accessible-invoice-edit',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/invoices/item.vue'),
                props: { accessible: true },
            },
            {
                path: '/reminders/my-reminders',
                name: 'my-reminders',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/reminders/own.vue'),
                props: { own: true },
            },
            {
                path: '/reminders/my-reminders/create',
                name: 'my-reminder-create',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/reminders/item.vue'),
                props: { own: true }
            },
            {
                path: '/reminders/my-reminders/:id(\\d+)',
                name: 'my-reminder-edit',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/reminders/item.vue'),
                props: { own: true },
            },
            {
                path: '/tasks/my-tasks',
                name: 'my-tasks',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/tasks/tasks/own.vue'),
                props: { own: true },
            },
            {
                path: '/tasks/my-tasks/create',
                name: 'my-task-create',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/tasks/tasks/item.vue'),
                props: { own: true }
            },
            {
                path: '/tasks/my-tasks/:id(\\d+)',
                name: 'my-task-edit',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/tasks/tasks/item.vue'),
                props: { own: true },
            },
            {
                path: '/tasks/accessible-tasks',
                name: 'accessible-tasks',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/tasks/tasks/accessible.vue'),
            },
            {
                path: '/tasks/accessible-tasks/:id(\\d+)',
                name: 'accessible-task-edit',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/tasks/tasks/item.vue'),
                props: { accessible: true },
            },
            {
                path: '/tasks/accessible-tasks/create',
                name: 'accessible-task-create',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/tasks/tasks/item.vue'),
                props: { accessible: true },
            },
            {
                path: '/master-data/countries',
                name: 'countries',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/countries/list.vue')
            },
            {
                path: '/master-data/countries/create',
                name: 'country-create',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/countries/item.vue')
            },
            {
                path: '/master-data/countries/:id(\\d+)',
                name: 'country-edit',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/countries/item.vue')
            },
            {
                path: '/master-data/zip-codes',
                name: 'zip-codes',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/zip-codes/list.vue')
            },
            {
                path: '/master-data/zip-codes/create',
                name: 'zip-code-create',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/zip-codes/item.vue')
            },
            {
                path: '/master-data/zip-codes/:id(\\d+)',
                name: 'zip-code-edit',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/zip-codes/item.vue')
            },
            {
                path: '/master-data/street-types',
                name: 'street-types',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/street-types/list.vue')
            },
            {
                path: '/master-data/street-types/create',
                name: 'street-type-create',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/street-types/item.vue')
            },
            {
                path: '/master-data/street-types/:id(\\d+)',
                name: 'street-type-edit',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/street-types/item.vue')
            },
            {
                path: '/master-data/positions',
                name: 'positions',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/positions/list.vue')
            },
            {
                path: '/master-data/positions/create',
                name: 'position-create',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/positions/item.vue')
            },
            {
                path: '/master-data/positions/:id(\\d+)',
                name: 'position-edit',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/positions/item.vue')
            },
            {
                path: '/master-data/company-sizes',
                name: 'company-sizes',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/company-sizes/list.vue')
            },
            {
                path: '/master-data/company-sizes/create',
                name: 'company-size-create',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/company-sizes/item.vue')
            },
            {
                path: '/master-data/company-sizes/:id(\\d+)',
                name: 'company-size-edit',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/company-sizes/item.vue')
            },
            {
                path: '/master-data/industries',
                name: 'industries',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/industries/list.vue')
            },
            {
                path: '/master-data/industries/create',
                name: 'industry-create',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/industries/item.vue')
            },
            {
                path: '/master-data/industries/:id(\\d+)',
                name: 'industry-edit',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/industries/item.vue')
            },
            {
                path: '/master-data/lead-sources',
                name: 'lead-sources',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/lead-sources/list.vue')
            },
            {
                path: '/master-data/lead-sources/create',
                name: 'lead-source-create',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/lead-sources/item.vue')
            },
            {
                path: '/master-data/lead-sources/:id(\\d+)',
                name: 'lead-source-edit',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/lead-sources/item.vue')
            },
            {
                path: '/master-data/address-types',
                name: 'address-types',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/address-types/list.vue')
            },
            {
                path: '/master-data/address-types/create',
                name: 'address-type-create',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/address-types/item.vue')
            },
            {
                path: '/master-data/address-types/:id(\\d+)',
                name: 'address-type-edit',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/address-types/item.vue')
            },
            {
                path: '/master-data/field-types',
                name: 'field-types',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/field-types/list.vue')
            },
            {
                path: '/master-data/field-types/create',
                name: 'field-type-create',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/field-types/item.vue')
            },
            {
                path: '/master-data/field-types/:id(\\d+)',
                name: 'field-type-edit',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/field-types/item.vue')
            },
            {
                path: '/master-data/additional-data/:type?',
                name: 'additional-data',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/additional-data/manager.vue')
            },
            {
                path: '/master-data/additional-data/create/:type?',
                name: 'additional-data-create',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/additional-data/item.vue')
            },
            {
                path: '/master-data/additional-data/:id(\\d+)/:type?',
                name: 'additional-data-edit',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/additional-data/item.vue')
            },
            {
                path: '/system',
                name: 'system',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                }
            },
            {
                path: '/authorization/permissions',
                name: 'roles',
                meta: {
                    authentication_required: true,
                    authorization_required: 'roles.view'
                },
                component: () => import('../views/pages/roles/list.vue')
            },
            {
                path: '/authorization/permissions/create',
                name: 'role-create',
                meta: {
                    authentication_required: true,
                    authorization_required: 'roles.create'
                },
                component: () => import('../views/pages/roles/item.vue')
            },
            {
                path: '/authorization/permissions/:id(\\d+)',
                name: 'role-edit',
                meta: {
                    authentication_required: true,
                    authorization_required: 'roles.update'
                },
                component: () => import('../views/pages/roles/item.vue')
            },
            {
                path: '/authorization/role-groups',
                name: 'role-groups',
                meta: {
                    authentication_required: true,
                    authorization_required: 'role_groups.view'
                },
                component: () => import('../views/pages/role-groups/list.vue')
            },
            {
                path: '/authorization/role-groups/create',
                name: 'role-group-create',
                meta: {
                    authentication_required: true,
                    authorization_required: 'role_groups.create'
                },
                component: () => import('../views/pages/role-groups/item.vue')
            },
            {
                path: '/authorization/role-groups/:id(\\d+)',
                name: 'role-group-edit',
                meta: {
                    authentication_required: true,
                    authorization_required: 'role_groups.update'
                },
                component: () => import('../views/pages/role-groups/item.vue')
            },
            {
                path: '/authorization/role-categories',
                name: 'role-categories',
                meta: {
                    authentication_required: true,
                    authorization_required: 'role_categories.view'
                },
                component: () => import('../views/pages/role-categories/list.vue')
            },
            {
                path: '/authorization/role-categories/create',
                name: 'role-category-create',
                meta: {
                    authentication_required: true,
                    authorization_required: 'role_categories.create'
                },
                component: () => import('../views/pages/role-categories/item.vue')
            },
            {
                path: '/authorization/role-categories/:id(\\d+)',
                name: 'role-category-edit',
                meta: {
                    authentication_required: true,
                    authorization_required: 'role_categories.update'
                },
                component: () => import('../views/pages/role-categories/item.vue')
            },
            {
                path: '/authorization/modules',
                name: 'modules',
                meta: {
                    authentication_required: true,
                    authorization_required: 'modules.view'
                },
                component: () => import('../views/pages/modules/list.vue')
            },
            {
                path: '/authorization/modules/create',
                name: 'module-create',
                meta: {
                    authentication_required: true,
                    authorization_required: 'modules.create'
                },
                component: () => import('../views/pages/modules/item.vue')
            },
            {
                path: '/authorization/modules/:id(\\d+)',
                name: 'module-edit',
                meta: {
                    authentication_required: true,
                    authorization_required: 'modules.update'
                },
                component: () => import('../views/pages/modules/item.vue')
            },
            {
                path: '/authorization/individuals',
                name: 'individuals',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/authorization/matrix.vue')
            },
            {
                path: '/authorization/individual/role-group',
                name: 'individual-role-group',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/authorization/grant-group.vue')
            },
            {
                path: '/users',
                name: 'users',
                meta: {
                    authentication_required: true,
                    authorization_required: 'users.view'
                },
                component: () => import('../views/pages/users/list.vue')
            },
            {
                path: '/users/create',
                name: 'user-create',
                meta: {
                    authentication_required: true,
                    authorization_required: 'users.create'
                },
                component: () => import('../views/pages/users/item.vue')
            },
            {
                path: '/users/:id(\\d+)',
                name: 'user-edit',
                meta: {
                    authentication_required: true,
                    authorization_required: 'users.update'
                },
                component: () => import('../views/pages/users/item.vue')
            },
            {
                path: '/users/profile',
                name: 'users-profile',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/users/profile-edit.vue')
            },
            {
                path: '/profiles/:id(\\d+)',
                name: 'public-profile',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/users/public-profile.vue')
            },
            {
                path: '/users/microsoft-graph',
                name: 'users-microsoft-graph',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/users/microsoft.vue')
            },
            {
                path: '/users/google-api',
                name: 'users-google-api',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/users/google.vue')
            },
            {
                path: '/menu-system/menus',
                name: 'menus',
                component: () => import('../views/pages/menus/list.vue')
            },
            {
                path: '/menu-system/menus/create',
                name: 'menu-create',
                component: () => import('../views/pages/menus/item.vue')
            },
            {
                path: '/menu-system/menus/:id(\\d+)',
                name: 'menu-edit',
                component: () => import('../views/pages/menus/item.vue')
            },
            {
                path: '/menu-system/menu-point-categories',
                name: 'menu-point-categories',
                component: () => import('../views/pages/menu-point-categories/list.vue')
            },
            {
                path: '/menu-system/menu-point-categories/create',
                name: 'menu-point-category-create',
                component: () => import('../views/pages/menu-point-categories/item.vue')
            },
            {
                path: '/menu-system/menu-point-categories/:id(\\d+)',
                name: 'menu-point-category-edit',
                component: () => import('../views/pages/menu-point-categories/item.vue')
            },
            {
                path: '/menu-system/menu-points',
                name: 'menu-points',
                component: () => import('../views/pages/menu-points/list.vue')
            },
            {
                path: '/menu-system/menu-points/create',
                name: 'menu-point-create',
                component: () => import('../views/pages/menu-points/item.vue')
            },
            {
                path: '/menu-system/menu-points/:id(\\d+)',
                name: 'menu-point-edit',
                component: () => import('../views/pages/menu-points/item.vue')
            },
            {
                path: '/files/:path*',
                name: 'file',
                component: () => import('../views/pages/files/item.vue')
            },
            {
                path: '/master-data/priorities',
                name: 'priorities',
                component: () => import('../views/pages/priorities/list.vue')
            },
            {
                path: '/master-data/priorities/create',
                name: 'priority-create',
                component: () => import('../views/pages/priorities/item.vue')
            },
            {
                path: '/master-data/priorities/:id(\\d+)',
                name: 'priority-edit',
                component: () => import('../views/pages/priorities/item.vue')
            },
            {
                path: '/master-data/statuses',
                name: 'statuses',
                component: () => import('../views/pages/statuses/list.vue')
            },
            {
                path: '/master-data/statuses/create',
                name: 'status-create',
                component: () => import('../views/pages/statuses/item.vue')
            },
            {
                path: '/master-data/statuses/:id(\\d+)',
                name: 'status-edit',
                component: () => import('../views/pages/statuses/item.vue')
            },
            {
                path: '/master-data/billable-categories',
                name: 'billable-categories',
                component: () => import('../views/pages/billable-categories/list.vue')
            },
            {
                path: '/master-data/billable-categories/create',
                name: 'billable-category-create',
                component: () => import('../views/pages/billable-categories/item.vue')
            },
            {
                path: '/master-data/billable-categories/:id(\\d+)',
                name: 'billable-category-edit',
                component: () => import('../views/pages/billable-categories/item.vue')
            },
            {
                path: '/master-data/contract-types',
                name: 'contract-types',
                component: () => import('../views/pages/contract-types/list.vue')
            },
            {
                path: '/master-data/contract-types/create',
                name: 'contract-type-create',
                component: () => import('../views/pages/contract-types/item.vue')
            },
            {
                path: '/master-data/contract-types/:id(\\d+)',
                name: 'contract-type-edit',
                component: () => import('../views/pages/contract-types/item.vue')
            },
            {
                path: '/master-data/implementers',
                name: 'implementers',
                component: () => import('../views/pages/implementers/list.vue')
            },
            {
                path: '/master-data/implementers/create',
                name: 'implementer-create',
                component: () => import('../views/pages/implementers/item.vue')
            },
            {
                path: '/master-data/implementers/:id(\\d+)',
                name: 'implementer-edit',
                component: () => import('../views/pages/implementers/item.vue')
            },
            {
                path: '/master-data/products',
                name: 'products',
                component: () => import('../views/pages/products/list.vue')
            },
            {
                path: '/master-data/products/create',
                name: 'product-create',
                component: () => import('../views/pages/products/item.vue')
            },
            {
                path: '/master-data/products/:id(\\d+)',
                name: 'product-edit',
                component: () => import('../views/pages/products/item.vue')
            },
            {
                path: '/master-data/services',
                name: 'services',
                component: () => import('../views/pages/services/list.vue')
            },
            {
                path: '/master-data/services/create',
                name: 'service-create',
                component: () => import('../views/pages/services/item.vue')
            },
            {
                path: '/master-data/services/:id(\\d+)',
                name: 'service-edit',
                component: () => import('../views/pages/services/item.vue')
            },
            {
                path: '/master-data/processes',
                name: 'processes',
                component: () => import('../views/pages/processes/list.vue')
            },
            {
                path: '/master-data/processes/create',
                name: 'process-create',
                component: () => import('../views/pages/processes/item.vue')
            },
            {
                path: '/master-data/processes/:id(\\d+)',
                name: 'process-edit',
                component: () => import('../views/pages/processes/item.vue')
            },
            {
                path: '/master-data/interactions',
                name: 'interactions',
                component: () => import('../views/pages/interactions/list.vue')
            },
            {
                path: '/master-data/interactions/create',
                name: 'interaction-create',
                component: () => import('../views/pages/interactions/item.vue')
            },
            {
                path: '/master-data/interactions/:id(\\d+)',
                name: 'interaction-edit',
                component: () => import('../views/pages/interactions/item.vue')
            },
            {
                path: '/master-data/tasks/types',
                name: 'tasks-types',
                component: () => import('../views/pages/tasks/types/list.vue')
            },
            {
                path: '/master-data/tasks/types/create',
                name: 'tasks-type-create',
                component: () => import('../views/pages/tasks/types/item.vue')
            },
            {
                path: '/master-data/tasks/types/:id(\\d+)',
                name: 'tasks-type-edit',
                component: () => import('../views/pages/tasks/types/item.vue')
            },
            {
                path: '/master-data/tasks/priorities',
                name: 'tasks-priorities',
                component: () => import('../views/pages/tasks/priorities/list.vue')
            },
            {
                path: '/master-data/tasks/priorities/create',
                name: 'tasks-priority-create',
                component: () => import('../views/pages/tasks/priorities/item.vue')
            },
            {
                path: '/master-data/tasks/priorities/:id(\\d+)',
                name: 'tasks-priority-edit',
                component: () => import('../views/pages/tasks/priorities/item.vue')
            },
            {
                path: '/master-data/tasks/statuses',
                name: 'tasks-statuses',
                component: () => import('../views/pages/tasks/statuses/list.vue')
            },
            {
                path: '/master-data/tasks/statuses/create',
                name: 'tasks-status-create',
                component: () => import('../views/pages/tasks/statuses/item.vue')
            },
            {
                path: '/master-data/tasks/statuses/:id(\\d+)',
                name: 'tasks-status-edit',
                component: () => import('../views/pages/tasks/statuses/item.vue')
            },
            {
                path: '/master-data/tasks/templates',
                name: 'tasks-templates',
                component: () => import('../views/pages/tasks/templates/list.vue')
            },
            {
                path: '/master-data/tasks/templates/create',
                name: 'tasks-template-create',
                component: () => import('../views/pages/tasks/templates/item.vue')
            },
            {
                path: '/master-data/tasks/templates/:id(\\d+)',
                name: 'tasks-template-edit',
                component: () => import('../views/pages/tasks/templates/item.vue')
            },
            {
                path: '/master-data/tasks/tasks',
                name: 'tasks-tasks',
                component: () => import('../views/pages/tasks/tasks/list.vue')
            },
            {
                path: '/master-data/tasks/tasks/create',
                name: 'tasks-task-create',
                component: () => import('../views/pages/tasks/tasks/item.vue')
            },
            {
                path: '/master-data/tasks/tasks/:id(\\d+)',
                name: 'tasks-task-edit',
                component: () => import('../views/pages/tasks/tasks/item.vue')
            },
            {
                path: '/master-data/invoices/currencies',
                name: 'invoices-currencies',
                component: () => import('../views/pages/currencies/list.vue')
            },
            {
                path: '/master-data/invoices/currencies/create',
                name: 'invoices-currency-create',
                component: () => import('../views/pages/currencies/item.vue')
            },
            {
                path: '/master-data/invoices/currencies/:id(\\d+)',
                name: 'invoices-currency-edit',
                component: () => import('../views/pages/currencies/item.vue')
            },
            {
                path: '/master-data/invoices/vats',
                name: 'invoices-vats',
                component: () => import('../views/pages/vats/list.vue')
            },
            {
                path: '/master-data/invoices/vats/create',
                name: 'invoices-vat-create',
                component: () => import('../views/pages/vats/item.vue')
            },
            {
                path: '/master-data/invoices/vats/:id(\\d+)',
                name: 'invoices-vat-edit',
                component: () => import('../views/pages/vats/item.vue')
            },
            {
                path: '/master-data/invoices/invoice-types',
                name: 'invoices-invoice-types',
                component: () => import('../views/pages/invoice-types/list.vue')
            },
            {
                path: '/master-data/invoices/invoice-types/create',
                name: 'invoices-invoice-type-create',
                component: () => import('../views/pages/invoice-types/item.vue')
            },
            {
                path: '/master-data/invoices/invoice-types/:id(\\d+)',
                name: 'invoices-invoice-type-edit',
                component: () => import('../views/pages/invoice-types/item.vue')
            },
            {
                path: '/master-data/invoices/units',
                name: 'invoices-units',
                component: () => import('../views/pages/units/list.vue')
            },
            {
                path: '/master-data/invoices/units/create',
                name: 'invoices-unit-create',
                component: () => import('../views/pages/units/item.vue')
            },
            {
                path: '/master-data/invoices/units/:id(\\d+)',
                name: 'invoices-unit-edit',
                component: () => import('../views/pages/units/item.vue')
            },
            {
                path: '/master-data/organizations/genders',
                name: 'genders',
                component: () => import('../views/pages/genders/list.vue')
            },
            {
                path: '/master-data/organizations/genders/create',
                name: 'gender-create',
                component: () => import('../views/pages/genders/item.vue')
            },
            {
                path: '/master-data/organizations/genders/:id(\\d+)',
                name: 'gender-edit',
                component: () => import('../views/pages/genders/item.vue')
            },
            {
                path: '/master-data/organizations/divisions',
                name: 'divisions',
                component: () => import('../views/pages/organization/divisions/list.vue')
            },
            {
                path: '/master-data/organizations/divisions/create',
                name: 'division-create',
                component: () => import('../views/pages/organization/divisions/item.vue')
            },
            {
                path: '/master-data/organizations/divisions/:id(\\d+)',
                name: 'division-edit',
                component: () => import('../views/pages/organization/divisions/item.vue')
            },
            {
                path: '/master-data/organizations/departments',
                name: 'departments',
                component: () => import('../views/pages/organization/departments/list.vue')
            },
            {
                path: '/master-data/organizations/departments/create',
                name: 'department-create',
                component: () => import('../views/pages/organization/departments/item.vue')
            },
            {
                path: '/master-data/organizations/departments/:id(\\d+)',
                name: 'department-edit',
                component: () => import('../views/pages/organization/departments/item.vue')
            },
            {
                path: '/master-data/organizations/hierarchy-levels',
                name: 'hierarchy-levels',
                component: () => import('../views/pages/organization/hierarchy-levels/list.vue')
            },
            {
                path: '/master-data/organizations/hierarchy-levels/create',
                name: 'hierarchy-level-create',
                component: () => import('../views/pages/organization/hierarchy-levels/item.vue')
            },
            {
                path: '/master-data/organizations/hierarchy-levels/:id(\\d+)',
                name: 'hierarchy-level-edit',
                component: () => import('../views/pages/organization/hierarchy-levels/item.vue')
            },
            {
                path: '/master-data/organizations/hierarchical-chains',
                name: 'hierarchical-chains',
                component: () => import('../views/pages/organization/hierarchical-chains/list.vue')
            },
            {
                path: '/master-data/organizations/hierarchical-chains/create',
                name: 'hierarchical-chain-create',
                component: () => import('../views/pages/organization/hierarchical-chains/item.vue')
            },
            {
                path: '/master-data/organizations/hierarchical-chains/:id(\\d+)',
                name: 'hierarchical-chain-edit',
                component: () => import('../views/pages/organization/hierarchical-chains/item.vue')
            },
            {
                path: '/master-data/organizations/knowledge-levels',
                name: 'knowledge-levels',
                component: () => import('../views/pages/organization/knowledge-levels/list.vue')
            },
            {
                path: '/master-data/organizations/knowledge-levels/create',
                name: 'knowledge-level-create',
                component: () => import('../views/pages/organization/knowledge-levels/item.vue')
            },
            {
                path: '/master-data/organizations/knowledge-levels/:id(\\d+)',
                name: 'knowledge-level-edit',
                component: () => import('../views/pages/organization/knowledge-levels/item.vue')
            },
            {
                path: '/master-data/organizations/locations',
                name: 'locations',
                component: () => import('../views/pages/organization/locations/list.vue')
            },
            {
                path: '/master-data/organizations/locations/create',
                name: 'location-create',
                component: () => import('../views/pages/organization/locations/item.vue')
            },
            {
                path: '/master-data/organizations/locations/:id(\\d+)',
                name: 'location-edit',
                component: () => import('../views/pages/organization/locations/item.vue')
            },
            {
                path: '/master-data/organizations/positions',
                name: 'org-positions',
                component: () => import('../views/pages/organization/positions/list.vue')
            },
            {
                path: '/master-data/organizations/positions/create',
                name: 'org-position-create',
                component: () => import('../views/pages/organization/positions/item.vue')
            },
            {
                path: '/master-data/organizations/positions/:id(\\d+)',
                name: 'org-position-edit',
                component: () => import('../views/pages/organization/positions/item.vue')
            },
            {
                path: '/master-data/organizations/skills',
                name: 'skills',
                component: () => import('../views/pages/organization/skills/list.vue')
            },
            {
                path: '/master-data/organizations/skills/create',
                name: 'skill-create',
                component: () => import('../views/pages/organization/skills/item.vue')
            },
            {
                path: '/master-data/organizations/skills/:id(\\d+)',
                name: 'skill-edit',
                component: () => import('../views/pages/organization/skills/item.vue')
            },
            {
                path: '/master-data/organizations/skill-levels',
                name: 'skill-levels',
                component: () => import('../views/pages/organization/skill-levels/list.vue')
            },
            {
                path: '/master-data/organizations/skill-levels/create',
                name: 'skill-level-create',
                component: () => import('../views/pages/organization/skill-levels/item.vue')
            },
            {
                path: '/master-data/organizations/skill-levels/:id(\\d+)',
                name: 'skill-level-edit',
                component: () => import('../views/pages/organization/skill-levels/item.vue')
            },
            {
                path: '/master-data/organizations/skill-types',
                name: 'skill-types',
                component: () => import('../views/pages/organization/skill-types/list.vue')
            },
            {
                path: '/master-data/organizations/skill-types/create',
                name: 'skill-type-create',
                component: () => import('../views/pages/organization/skill-types/item.vue')
            },
            {
                path: '/master-data/organizations/skill-types/:id(\\d+)',
                name: 'skill-type-edit',
                component: () => import('../views/pages/organization/skill-types/item.vue')
            },
            {
                path: '/master-data/organizations/specializations',
                name: 'specializations',
                component: () => import('../views/pages/organization/specializations/list.vue')
            },
            {
                path: '/master-data/organizations/specializations/create',
                name: 'specialization-create',
                component: () => import('../views/pages/organization/specializations/item.vue')
            },
            {
                path: '/master-data/organizations/specializations/:id(\\d+)',
                name: 'specialization-edit',
                component: () => import('../views/pages/organization/specializations/item.vue')
            },
            {
                path: '/master-data/organizations/teams',
                name: 'teams',
                component: () => import('../views/pages/organization/teams/list.vue')
            },
            {
                path: '/master-data/organizations/teams/create',
                name: 'team-create',
                component: () => import('../views/pages/organization/teams/item.vue')
            },
            {
                path: '/master-data/organizations/teams/:id(\\d+)',
                name: 'team-edit',
                component: () => import('../views/pages/organization/teams/item.vue')
            },
            {
                path: '/resource-monitor',
                name: 'system-resource-monitor',
                component: () => import('../views/pages/system/resource-monitor.vue')
            },
            {
                path: '/contributors',
                name: 'contributors',
                meta: {
                    authentication_required: true,
                    authorization_required: 'contributors.view'
                },
                component: () => import('../views/pages/contributors/list.vue')
            },
            {
                path: '/contributors/create',
                name: 'contributor-create',
                meta: {
                    authentication_required: true,
                    authorization_required: 'contributors.create'
                },
                component: () => import('../views/pages/contributors/item.vue')
            },
            {
                path: '/contributors/:id(\\d+)',
                name: 'contributor-edit',
                meta: {
                    authentication_required: true,
                    authorization_required: 'contributors.update'
                },
                component: () => import('../views/pages/contributors/item.vue')
            },
        ]
    },
    {
        path: '/auth/login',
        name: 'login',
        component: () => import('../views/pages/auth/login.vue')
    },
    {
        path: '/auth/logout',
        name: 'logout',
        component: () => import('../views/pages/auth/logout.vue')
    },
    {
        path: '/auth/register',
        name: 'register',
        component: () => import('../views/pages/auth/register.vue')
    },
    {
        path: '/auth/lock-screen',
        name: 'lock-screen',
        component: () => import('../views/pages/auth/lock-screen.vue')
    },
    {
        path: '/auth/recoverpwd',
        name: 'recoverpwd',
        component: () => import('../views/pages/auth/recoverpwd.vue')
    },
    {
        path: '/auth/confirm-mail',
        name: 'confirm-mail',
        component: () => import('../views/pages/auth/confirm-mail.vue')
    },
    {
        path: '/auth/two-step-verification',
        name: 'two-step-verification',
        component: () => import('../views/pages/auth/two-step-verification.vue')
    },
    {
        path: '/auth/email-verification',
        name: 'email-verification',
        component: () => import('../views/pages/auth/email-verification.vue')
    },
]
