import axios from './axios'
import qs from "qs";

function service(endpoint) {

    function generateToken(id, data = {}) {
        return axios.get(`${endpoint}/${id}/generateToken`, { params: data, paramsSerializer: params => qs.stringify(params, { encode: false })  }).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    return {
        generateToken
    }
}

export default service
