import axios from './axios'
import qs from "qs";

function service(endpoint) {

    function fetchOwn(data) {
        return axios.get(endpoint, { params: data, paramsSerializer: params => qs.stringify(params, { encode: false })  }).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function accept(id) {
        return axios.post(`offers/${id}/accept`).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function reject(id) {
        return axios.post(`offers/${id}/reject`).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    return {
        fetchOwn,
        accept,
        reject
    }
}

export default service
