import service from '@/api/positionSuperior';
import {handleError} from '@/state/generators/helpers';
import {alert} from "../generators/helpers";

export const state = {
    service: service(),
}

export const getters = {}

export const mutations = {}

export const actions = {
    updateAll: function ({commit, state}, pivots) {
        commit('app/SET_LOADING', true, {root: true})
        commit('SET_LOADING', true)

        return state.service.updateAll(pivots).then(response => {
            commit('SET_ALL', response.data)
            alert(commit, this.$t('request.creation_is_successful'))
            commit('app/SET_LOADING', false, {root: true})
            commit('SET_LOADING', false)
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        })
    }
}
