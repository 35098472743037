import service from '@/api/task';
import {loading, handleError} from '@/state/generators/helpers';
import {alert} from "../generators/helpers";

export const state = {
    ownLoading: false,
    ownPager: {},
    ownAdditional: {},
    own: [],
    accessibleLoading: false,
    accessiblePager: {},
    accessibleAdditional: {},
    accessible: [],
    service: service('tasks'),
    loading: false,
    list: [],
    all: [],
    item: {},
    errors: {},
}

export const getters = {
    ownLoading: function (state) {
        return !!state.ownLoading
    },
    ownPager: function (state) {
        return JSON.parse(JSON.stringify(state.ownPager))
    },
    own: function (state) {
        return state.own
    },
    accessibleLoading: function (state) {
        return !!state.accessibleLoading
    },
    accessiblePager: function (state) {
        return JSON.parse(JSON.stringify(state.accessiblePager))
    },
    accessible: function (state) {
        return state.accessible
    },
}

export const mutations = {
    'SET_LOADING': function (state, boolean = true) {
        state.loading = boolean
    },

    'SET_ERRORS': function (state, errors) {
        state.errors = errors
    },

    'SET_OWN_LOADING': function (state, boolean = true) {
        state.ownLoading = boolean
    },

    'SET_OWN': function (state, list) {
        state.own = list
    },

    'SET_OWN_PAGER': function (state, pager = {}) {
        state.ownPager = pager
    },

    'SET_OWN_ADDITIONAL': function (state, data = {}) {
        state.ownAdditional = data
    },

    'SET_ACCESSIBLE_LOADING': function (state, boolean = true) {
        state.accessibleLoading = boolean
    },

    'SET_ACCESSIBLE': function (state, list) {
        state.accessible = list
    },

    'SET_ACCESSIBLE_PAGER': function (state, pager = {}) {
        state.accessiblePager = pager
    },

    'SET_ACCESSIBLE_ADDITIONAL': function (state, data = {}) {
        state.accessibleAdditional = data
    },

    'CLOSE_ITEM': function (state, id) {
        const closed = (new Date()).formattedDate();
        const index = state.list.findIndex(listItem => listItem.id === id);

        if (index !== -1) {
            state.list[index].closed_at = closed;
            state.list = JSON.parse(JSON.stringify(state.list))
        }

        const allIndex = state.all.findIndex(allItem => allItem.id === id);

        if (allIndex !== -1) {
            state.all[allIndex].closed_at = closed;
            state.all = JSON.parse(JSON.stringify(state.all))
        }

        const ownIndex = state.own.findIndex(ownItem => ownItem.id === id);

        if (ownIndex !== -1) {
            state.own[ownIndex].closed_at = closed;
            state.own = JSON.parse(JSON.stringify(state.own))
        }

        const accessibleIndex = state.accessible.findIndex(accessibleItem => accessibleItem.id === id);

        if (accessibleIndex !== -1) {
            state.accessible[accessibleIndex].closed_at = closed;
            state.accessible = JSON.parse(JSON.stringify(state.accessible))
        }

        if (state.item?.id === id) {
            state.item.closed_at = closed;
            state.item = JSON.parse(JSON.stringify(state.item))
        }
    },
}

export const actions = {
    own: function ({commit, state}, filters) {
        loading(commit)

        return state.service.fetchOwn(filters).then(response => {
            commit('SET_OWN', response.data)
            commit('SET_OWN_PAGER', response.meta)
            commit('SET_OWN_ADDITIONAL', response.additional)
            loading(commit, false)
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    accessible: function ({commit, state}, filters) {
        loading(commit)

        return state.service.fetchAccessible(filters).then(response => {
            commit('SET_ACCESSIBLE', response.data)
            commit('SET_ACCESSIBLE_PAGER', response.meta)
            commit('SET_ACCESSIBLE_ADDITIONAL', response.additional)
            loading(commit, false)
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    close: function ({commit, state}, {id, query}) {
        commit('app/SET_LOADING', true, {root: true})
        commit('SET_LOADING', true)

        return state.service.close(id, query).then(response => {

            commit('CLOSE_ITEM', id);
            alert(commit, this.$t('request.save_is_successful'))
            commit('SET_ERRORS', {});
            commit('app/SET_LOADING', false, {root: true})
            commit('SET_LOADING', false)

            return response.data;
        }).catch((e) => {
            handleError(e, commit)
        })
    },
}
