import service from '@/api/document';
import {handleError} from '@/state/generators/helpers';
import {alert} from "../generators/helpers";

export const state = {
    service: service('documents'),
    loading: false,
    list: [],
    all: [],
    item: {},
    errors: {},
}

export const getters = {

}

export const mutations = {
    'SET_LOADING': function (state, boolean = true) {
        state.loading = boolean
    },

    'SET_ERRORS': function (state, errors) {
        state.errors = errors
    },

    'REALIZE_ITEM': function (state, id) {
        const date = (new Date()).formattedDate();
        const index = state.list.findIndex(listItem => listItem.id === id);

        if (index !== -1) {
            state.list[index].realized_at = date;
            state.list = JSON.parse(JSON.stringify(state.list))
        }

        const allIndex = state.all.findIndex(allItem => allItem.id === id);

        if (allIndex !== -1) {
            state.all[allIndex].realized_at = date;
            state.all = JSON.parse(JSON.stringify(state.all))
        }
    },
}

export const actions = {
    realize: function ({commit, state}, id) {
        commit('app/SET_LOADING', true, {root: true})
        commit('SET_LOADING', true)

        return state.service.realize(id).then(response => {

            commit('REALIZE_ITEM', id);
            alert(commit, this.$t('request.save_is_successful'))
            commit('SET_ERRORS', {});
            commit('app/SET_LOADING', false, {root: true})
            commit('SET_LOADING', false)

            return response.data;
        }).catch((e) => {
            handleError(e, commit)
        })
    },
}
