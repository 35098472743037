
export const state = {
    all: [],
}

export const mutations = {
    'ORDER_BY': function (state, prop) {
        state.all.sort((a,b) => {
            return a[prop] === b[prop] ? 0 : (a[prop] > b[prop] ? -1 : 1)
        })
    },

    'SET_IS_ACTIVE_FALSE': function (state, {id, currencyId}) {
        state.all.forEach((item, index) => {
            if (item.id !== id && item.currency_id === currencyId) {
                state.all[index].is_active = false
            }
        })
    },

    'SET_ALL': function (state, all) {
        state.all = all
    },
}

export const actions = {
    orderByIsActive: function ({commit, dispatch}, price) {
        commit('SET_IS_ACTIVE_FALSE', {id: price.id, currencyId: price.currency_id})
        dispatch('orderBy')
    },

    orderBy: function ({commit, state, rootState}, prop = 'is_active') {
        const all = JSON.parse(JSON.stringify(state.all))

        all.sort((a,b) => {
            const ac = rootState.currency.all.find(cur => cur.id === a.currency_id)
            const bc = rootState.currency.all.find(cur => cur.id === b.currency_id)
            const ab = !!a[prop]
            const bb = !!b[prop]

            return ab === bb
                ? (ac.sort === bc.sort ? 0 : (ac.sort > bc.sort ? 1 : -1))
                : (ab > bb ? -1 : 1)
        })

        commit('SET_ALL', all)
    },
}
