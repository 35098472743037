import service from '@/api/projectStepInteraction';
import {handleError} from '@/state/generators/helpers';
import {loading} from "../generators/helpers";

export const state = {
    countedLoading: false,
    counted: {},
    service: service('projectStepInteractions'),
    projectList: [],
    projectPager: {},
    projectAdditional: {},
}

export const getters = {
    countedLoading: function (state) {
        return !!state.countedLoading
    },
    counted: function (state) {
        return state.counted
    },
    projectPager: function (state) {
        return JSON.parse(JSON.stringify(state.projectPager))
    },
    projectList: function (state) {
        return JSON.parse(JSON.stringify(state.projectList))
    },
    projectAdditional: function (state) {
        return JSON.parse(JSON.stringify(state.projectAdditional))
    },
}

export const mutations = {
    'REMOVE_ITEM': function (state, id) {
        const index = state.list.findIndex(item => item.id === id);

        if (index !== -1) {
            state.list.splice(index, 1)
        }

        const allIndex = state.all.findIndex(item => item.id === id);

        if (allIndex !== -1) {
            state.all.splice(index, 1)
        }

        const projectListIndex = state.projectList.findIndex(item => item.id === id);

        if (projectListIndex !== -1) {
            state.projectList.splice(projectListIndex, 1)
        }
    },

    'SET_COUNTED_LOADING': function (state, boolean = true) {
        state.countedLoading = boolean
    },

    'SET_COUNTED': function (state, list) {
        state.counted = list
    },

    'INCREASE': function (state, key) {
        if (!state.counted[key]) {
            state.counted[key] = 0
        }

        state.counted[key]++
    },

    'DECREASE': function (state, key) {
        if (state.counted[key] && state.counted[key] > 0) {
            state.counted[key]--
        }
    },

    'SET_PROJECT_LIST': function (state, list) {
        state.projectList = list
    },

    'SET_PROJECT_PAGER': function (state, pager = {}) {
        state.projectPager = pager
    },

    'SET_PROJECT_ADDITIONAL': function (state, data = {}) {
        state.projectAdditional = data
    },
}

export const actions = {
    counted: function ({commit, state}, filters) {
        commit('app/SET_LOADING', true, {root: true})
        commit('SET_COUNTED_LOADING', true)

        return state.service.fetchCounted(filters).then(response => {
            commit('SET_COUNTED', response.data)
            commit('app/SET_LOADING', false, {root: true})
            commit('SET_COUNTED_LOADING', false)
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    byProject: function ({commit, state}, filters) {
        loading(commit)

        return state.service.fetchProjectList(filters).then(response => {
            commit('SET_PROJECT_LIST', response.data)
            commit('SET_PROJECT_PAGER', response.meta)
            commit('SET_PROJECT_ADDITIONAL', response.additional)
            loading(commit, false)
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    clearCounted: function ({commit}) {
        commit('SET_COUNTED', {})
    },

    increase: function ({commit}, id) {
        commit('INCREASE', id)
    },

    decrease: function ({commit}, id) {
        commit('DECREASE', id)
    },
}
